import { useEffect } from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { WorkZone } from "../../models/Project";

export const ProjectAreaComponent = ({ area, map }: ProjectAreaProps) => {
  const maps = useMapsLibrary("maps");

  useEffect(() => {
    if (maps && area) {
      const polygon = new maps.Polygon({
        paths: area.coordinates[0].map(c => ({ lat: c[1], lng: c[0] })),
        fillColor: "#173232",
        fillOpacity: 0.15,
        strokeColor: "#173232",
        strokeOpacity: 0.4,
        strokeWeight: 2,
      });

      polygon.setMap(map);

      return () => {
        polygon.setMap(null);
      };
    }
  }, [maps, area, map]);

  return null;
};

export interface ProjectAreaProps {
  area: WorkZone["area"];
  map: google.maps.Map | null;
}