import { Fragment, useState } from "react";
import {
  Table,
  Text,
  Title,
  ScrollArea,
  Checkbox,
  Group,
  Button,
  Center,
  Switch,
} from "@mantine/core";
import circleDotted from "./StatusIcons/circle-dotted.png";
import circleAlert from "./StatusIcons/alert-circle.png";
import circleCheck from "./StatusIcons/circle-check.png";
import squareGray from "./StatusIcons/square-gray.png";
import roadSignImg from "../../../assets/road-sign.png";
import barrierImg from "../../../assets/barrier-block.png";
import { useMobileDevice } from "../../../hooks/useMobileDevice";
import { AlarmStatus, EquipmentType } from "../../../models/enums/DeviceEnums";
import {
  DeviceEntry,
  SupervisionReportDto,
} from "../../../models/SupervisionReport.ts";
import { createPDF, PdfProps } from "./ExportPdfHelper.ts";
import {
  IconBarrierBlock,
  IconCheck,
  IconEyeOff,
  IconRoadSign,
} from "@tabler/icons-react";
import { getImageUrlForSign } from "../../../utils.tsx";

export interface SupervisionReportProps {
  data: SupervisionReportDto | undefined;
  intervalInHours: number | null;
  exportStartDate: Date;
  exportEndDate: Date;
  projectId: string;
  projectName: string;
}

export const SupervisionReportTable = (props: SupervisionReportProps) => {
  const isMobile = useMobileDevice();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSnoozed, setShowSnoozed] = useState<boolean>(true);

  if (!props.data?.rows) {
    return <Title order={4}>No data available</Title>;
  }

  const filteredRows = props.data.rows.filter(
    (_, idx) => !selectedRows.includes(idx),
  );

  const getStatus = (entry: DeviceEntry) => {
    const logsToShow = entry?.logs?.length
      ? showSnoozed
        ? entry.logs
        : entry.logs.filter((dl) => !dl.workZoneSnoozed)
      : [];

    const iconSrc = () => {
      if (logsToShow.length === 0) return circleDotted;
      if (logsToShow.some((log) => log.alarmStatus === AlarmStatus.Alarming))
        return circleAlert;
      return circleCheck;
    };

    return (
      <>
        <img width="20px" src={iconSrc()} alt="" />
        {logsToShow.length}
      </>
    );
  };

  const allTimestamps = Array.from(new Set(props.data.intervals)).sort();

  const statusExplanation = (text: string, img: string) => {
    return (
      <Group gap={"xs"} align="center">
        <img width={"20px"} src={img} alt="" />
        <Text c={"gray.7"} size="xs">
          {text}
        </Text>
      </Group>
    );
  };

  const getRoadSignImageAsync = (attachmentRef: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const roadSignUrl = getImageUrlForSign(attachmentRef as string, true);
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = roadSignUrl ?? "";
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error("Image load error"));
    });
  };

  const imageMap = new Map<string, HTMLImageElement>();
  const imagePromises: Promise<void>[] = [];

  // Remove unwanted characters from the name
  filteredRows.forEach((row) => {
    row.name = row.name.replace(/(\r\n|\n|\r)/gm, " ");
  });

  filteredRows.forEach((row) => {
    const promise = getRoadSignImageAsync(row.attachmentRef).then((img) => {
      if (!row.attachmentRef) {
        if (row.currentEquipmentType === EquipmentType.Barrier) {
          img.src = barrierImg;
          imageMap.set(EquipmentType.Barrier.toString(), img);
        } else {
          img.src = roadSignImg;
          imageMap.set(EquipmentType.Sign.toString(), img);
        }
        return;
      }
      imageMap.set(row.attachmentRef, img);
    });
    imagePromises.push(promise);
  });

  const getRoadSign = (attachmentRef: string) => {
    const roadSignUrl = getImageUrlForSign(attachmentRef);
    return roadSignUrl ? (
      <img
        style={{
          maxWidth: "40px",
          maxHeight: "25px",
          width: "auto",
          height: "auto",
        }}
        src={roadSignUrl}
        alt=""
      />
    ) : (
      <IconRoadSign stroke={1.5} color="gray" size={25} />
    );
  };

  const equipmentImage = (
    currentEquipmentType: EquipmentType,
    attachmentRef: string,
  ) =>
    currentEquipmentType === EquipmentType.Barrier ? (
      <IconBarrierBlock stroke={1.5} color="gray" size={25} />
    ) : (
      getRoadSign(attachmentRef)
    );

  return (
    <>

      <Group justify="end">
        <Text c={"gray.7"} size="xs">
          Interval: {props.intervalInHours}h
        </Text>
        {[
          { text: "OK", img: circleCheck },
          { text: "Fallen", img: circleAlert },
          { text: "No Log", img: circleDotted },
          { text: "Snoozed", img: squareGray },
        ].map((s, index) => (
          <Fragment key={index}>{statusExplanation(s.text, s.img)}</Fragment>
        ))}
        <Switch
          label="Show snoozed"
          checked={showSnoozed}
          onChange={(event) => setShowSnoozed(event.currentTarget.checked)}
        />
        <Button
          loading={isLoading}
          onClick={() => {
            if (window._paq) {
              window._paq.push([
                "trackEvent",
                "SupervisionReport",
                "Export Button Clicked",
                props.projectId,
              ]);
            }
            setIsLoading(true);
            Promise.all(imagePromises).then(() => {
              const pdfProps: PdfProps = {
                timeStamps: allTimestamps,
                intervalInHours: props.intervalInHours ?? 8,
                rows: filteredRows ?? [],
                projectName: props.projectName,
                exportStartDate: props.exportStartDate,
                exportEndDate: props.exportEndDate,
                showSnoozed: showSnoozed,
                imageMap
              };
              createPDF(pdfProps).then(() => setIsLoading(false));
            });
          }}
        >
          Export
        </Button>
      </Group>
      <ScrollArea.Autosize mah={"55vh"}>
        <Table
          withRowBorders
          highlightOnHover
          withTableBorder
          withColumnBorders
          bg={"white"}
          stickyHeader
          id="pdf"
        >
          <Table.Thead bg={"deepGreen.7"} c={"white"}>
            <Table.Tr>
              <Table.Th ta={"center"} miw={"10px"}>
                Hide
              </Table.Th>
              {isMobile ? null : (
                <Table.Th ta={"center"} miw={"120px"}>
                  Reference Id
                </Table.Th>
              )}
              <Table.Th ta={"center"} miw={"120px"}>
                Additional info
              </Table.Th>
              <Table.Th ta={"center"} miw={"60px"}>
                Attachment
              </Table.Th>
              {allTimestamps.map((timestamp, index) => (
                <Table.Th fw={500} ta={"center"} key={index}>
                  <Text size="sm" fw="600">
                    {new Date(timestamp).toLocaleDateString("en-US", {
                      weekday: "short",
                    })}{" "}
                  </Text>
                  {formatTime(timestamp)}
                  <br />
                  {formatTime(
                    getNextTimestamp(timestamp, props.intervalInHours),
                  )}
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {props.data.rows?.map((row, rowIndex) => (
              <Table.Tr
                key={rowIndex}
                bg={selectedRows.includes(rowIndex) ? "gray.2" : undefined}
              >
                <Table.Td key={`hide-${rowIndex}`} miw={"10px"}>
                  <Center>
                    <Checkbox
                      aria-label="Select row"
                      key={`checkbox-${row}`}
                      data-testid={`hideCheckbox-${rowIndex}`}
                      checked={selectedRows.includes(rowIndex)}
                      icon={({ indeterminate, ...others }) =>
                        indeterminate ? (
                          <IconCheck {...others} />
                        ) : (
                          <IconEyeOff {...others} />
                        )
                      }
                      onChange={(event) => {
                        setSelectedRows(
                          event.currentTarget.checked
                            ? [...selectedRows, rowIndex]
                            : selectedRows.filter(
                              (position) => position !== rowIndex,
                            ),
                        );
                      }}
                    />
                  </Center>
                </Table.Td>
                {isMobile ? null : (
                  <Table.Td ta={"center"} fw="400" key={`ref-${rowIndex}`}>
                    {row.referenceId}
                  </Table.Td>
                )}
                <Table.Td fw="400" key={`name-${rowIndex}`}>
                  {row.name}
                </Table.Td>
                <Table.Td key={`type-${rowIndex}`}>
                  <Center>
                    {equipmentImage(
                      row.currentEquipmentType,
                      row.attachmentRef,
                    )}
                  </Center>
                </Table.Td>
                {allTimestamps.map((_, idx) => {
                  const entry = row.entries[idx];
                  return (
                    <Table.Td
                      key={`entry-${rowIndex}-${idx}`}
                      style={{
                        background:
                          showSnoozed &&
                            entry?.logs?.some((log) => log.workZoneSnoozed)
                            ? "#CED4DA"
                            : "transparent",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        {getStatus(entry)}
                      </div>
                    </Table.Td>
                  );
                })}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </ScrollArea.Autosize >
    </>
  );
};

function formatTime(dateString: string) {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
}

function getNextTimestamp(
  dateString: string,
  intervalInHours: number | null,
) {
  const date = new Date(dateString);
  date.setHours(date.getHours() + (intervalInHours ?? 0));
  return date.toISOString();
}

export default SupervisionReportTable;