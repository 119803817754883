import {
  Alert,
  Divider,
  Group,
  Input,
  Modal,
  Paper,
  SegmentedControl,
  Stack,
  Text,
} from "@mantine/core";
import { useMobileDevice } from "../../../hooks/useMobileDevice.tsx";
import { LocationMode } from "../../../shared/types.tsx";
import { IconAlertCircle, IconMap2 } from "@tabler/icons-react";
import classes from "./EditAndCreateProject.module.css";
import AutoCompleteGoogleMapComponent from "../../Map/AutoCompleteGoogleMap.tsx";
import { APIProvider } from "@vis.gl/react-google-maps";
import { useState } from "react";
import { EditAndCreateProjectInputForm } from "./EditAndCreateProjectInputForm.tsx";
import useCountries from "../../../data/hooks/Country/useCountriesHook.ts";
import { useMsal } from "@azure/msal-react";
import { ConfirmButtonGroup } from "../../../shared/ConfirmButtonGroup/ConfirmButtonGroup.tsx";
import useDepots from "../../../data/hooks/Depots/useDepots.tsx";
import { CreateWorkZone } from "../../../models/Project.ts";
import useMyUserAccesses from "../../../data/hooks/UserAccess/useMyUserAccess.ts";

interface Props {
  createNewProject?: boolean;
  isOpen: boolean;
  close: () => void;
  onSubmit: (project: CreateWorkZone) => void;
  onChange: (
    location: ManualCenterPoint | null,
    centerPointLocationMode: LocationMode,
  ) => void;
}

export interface ManualCenterPoint {
  address: string;
  position: { lat: number; lng: number };
}

export const EditAndCreateProjectModal = ({
  createNewProject = false,
  isOpen,
  close,
  onSubmit,
  onChange,
}: Props) => {
  const isMobile = useMobileDevice();
  const { instance } = useMsal();

  const { countries } = useCountries({ instance });
  const { depots } = useDepots(instance);
  const { userAccesses } = useMyUserAccesses({ instance: instance });

  const [centerPoint, setCenterPoint] = useState<ManualCenterPoint | null>(
    null,
  );
  const [locationMode, setLocationMode] = useState<LocationMode>(
    LocationMode.Automatic,
  );
  const [createForm, setCreateForm] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const createProject = async (data: CreateWorkZone) => {
    const manualCenter = locationMode == LocationMode.Manual;

    if (manualCenter && centerPoint == null) {
      setError(true);
      setTimeout(() => setError(false), 5000);
      return;
    }

    onSubmit({
      ...data,
      center:
        manualCenter && centerPoint
          ? {
              type: "Point",
              coordinates: [centerPoint.position.lng, centerPoint.position.lat],
            }
          : null,
      manualCenter: manualCenter,
    });
  };

  return (
    <Modal.Root
      opened={isOpen}
      onClose={close}
      fullScreen={isMobile}
      size="900px"
      centered
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fw={600}>
            {createNewProject ? "New Project" : "Edit location"}
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Group wrap={isMobile ? "wrap" : "nowrap"} align="top">
            <Stack miw={isMobile ? "100%" : "50%"}>
              {createNewProject && (
                <EditAndCreateProjectInputForm
                  create={createForm}
                  onSubmit={(data) => {
                    if (data == null) {
                      setCreateForm(false);
                      return;
                    }
                    createProject(data);
                  }}
                  initialValues={null}
                  countries={countries.filter((c) =>
                    userAccesses?.countries.includes(c.code),
                  )}
                  depots={depots}
                />
              )}
              <Group grow>
                <Input.Wrapper label="Location" required>
                  <SegmentedControl
                    defaultValue={locationMode}
                    data={[LocationMode.Automatic, LocationMode.Manual]}
                    fullWidth
                    onChange={(mode) =>
                      setLocationMode(
                        mode == "Automatic"
                          ? LocationMode.Automatic
                          : LocationMode.Manual,
                      )
                    }
                  />
                </Input.Wrapper>
              </Group>
              <Paper
                p={"md"}
                radius="md"
                className={classes["LocationInfo"]}
                ta={
                  centerPoint && locationMode == LocationMode.Manual
                    ? "left"
                    : "center"
                }
              >
                {locationMode == LocationMode.Automatic ? (
                  <>
                    <IconMap2 size={35} />
                    <Text size="sm" fw={200}>
                      Location will be based on device location
                    </Text>
                  </>
                ) : centerPoint && locationMode == LocationMode.Manual ? (
                  <>
                    <Text>{centerPoint.address}</Text>
                    <Text opacity={0.5} fw={300} size="xs">
                      {centerPoint.position.lat.toString()},{" "}
                      {centerPoint.position.lng.toString()}
                    </Text>
                  </>
                ) : (
                  <>
                    <IconMap2 size={35} />
                    <Text size="sm" fw={200}>
                      No location specified
                    </Text>
                  </>
                )}
              </Paper>
              {error && (
                <Alert
                  variant="light"
                  color="red"
                  title="No location specified"
                  icon={<IconAlertCircle />}
                >
                  You need to choose a location for your project
                </Alert>
              )}
            </Stack>
            <div style={{ width: "100%" }}>
              <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
                <AutoCompleteGoogleMapComponent
                  mapOverlay={locationMode == LocationMode.Automatic}
                  onUpdate={(centerPoint) => {
                    setError(false);
                    setCenterPoint(centerPoint);
                  }}
                />
              </APIProvider>
            </div>
          </Group>
          <Divider my={"md"} />
          <ConfirmButtonGroup
            confirmBtnText={createNewProject ? "Create" : "Save"}
            onAbort={close}
            onConfirm={() => {
              if (createNewProject) {
                setCreateForm(true);
                return;
              } else {
                if (
                  centerPoint == null &&
                  locationMode == LocationMode.Manual
                ) {
                  setError(true);
                  setTimeout(() => setError(false), 5000);
                } else {
                  onChange(centerPoint, locationMode);
                  close();
                }
              }
            }}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
