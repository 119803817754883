export const getMobileOperatingSystem = () => {
  // @ts-expect-error testing
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "windows";
  }

  if (/android/i.test(userAgent)) {
    return "android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-expect-error testing
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return "unknown";
};

export const getPWAStatus = () => {
  let displayMode = "browser";
  const mqStandAlone = "(display-mode: standalone)";
  // @ts-expect-error test
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    displayMode = "standalone";
  }

  return displayMode; // Chrome PWA (supporting fullscreen, standalone, minimal-ui)
};

export const getBrowser = () => {
  let currentBrowser = "unknown";
  if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
    currentBrowser = "chrome";
  } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
    currentBrowser = "firefox";
  } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
    currentBrowser = "safari";
  }

  return currentBrowser;
};
