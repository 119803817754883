import { UnstyledButton, Group, Avatar, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useMsal } from "@azure/msal-react";
import styles from "./UserButton.module.css";

type Props = {
  minimized: boolean;
};

export function UserButton({ minimized }: Props) {
  const { instance } = useMsal();
  const {
    name = "Sven Sevensson",
    username = "sven.svensson@ramuddenglobal.com",
  } = instance.getAllAccounts()[0] || {};
  const nameParts = name.split(" ");

  return (
    <UnstyledButton w={"100%"} className={styles.user}>
      <Group w={"100%"} wrap="nowrap" justify={minimized ? "center" : "start"}>
        <Avatar color="blue" radius="xl">
          {nameParts[0][0] + nameParts[1][0]}
        </Avatar>
        {minimized ? null : (
          <>
            <div style={{ flexGrow: 1 }}>
              <Text size="sm" fw={500}>
                {name}
              </Text>

              <Text c="dimmed" size="xs">
                {username.length > 26
                  ? username.substring(0, 26) + "..."
                  : username}
              </Text>
            </div>
            <IconChevronRight stroke={2} />
          </>
        )}
      </Group>
    </UnstyledButton>
  );
}
