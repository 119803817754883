import { Button, Group } from "@mantine/core";
import { useMobileDevice } from "../../hooks/useMobileDevice";

type Props = {
  confirmBtnText?: string;
  disabled?: boolean;
  onlyConfirm?: boolean;
  isLoading?: boolean;
  onAbort: () => void;
  onConfirm: () => void;
};

export const ConfirmButtonGroup = ({
  confirmBtnText = "Confirm",
  disabled,
  onlyConfirm,
  isLoading,
  onAbort,
  onConfirm,
}: Props) => {
  const isMobile = useMobileDevice();
  return (
    <Group grow={isMobile} gap={"sm"} justify="end">
      {!onlyConfirm && (
        <Button
          miw={isMobile ? "100%" : ""}
          variant="outline"
          onClick={onAbort}
          disabled={disabled}
        >
          Cancel
        </Button>
      )}
      <Button
        loading={isLoading}
        miw={isMobile ? "100%" : ""}
        onClick={onConfirm}
        disabled={disabled}
      >
        {confirmBtnText}
      </Button>
    </Group>
  );
};
