import { Flex, Group, Loader, Stack, Text } from "@mantine/core";
import OngoingInstallationDeviceList from "../components/OngoingInstallationDeviceList";
import { useContext } from "react";
import { PWAContext } from "../providers/PWAProvider";
import useProjectDevices from "../../data/hooks/Device/useProjectDevicesHook";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import PwaPageHeading from "../components/PwaPageHeading";
import { AlarmStatus, BatteryStatus } from "../../models/enums/DeviceEnums";
import { Device } from "../../models/Device";

interface Props {
  filter: "All Devices" | "Alarms" | "Battery";
}

export default function PwaDeviceListPage({ filter }: Props) {
  const { instance } = useMsal();
  const { project } = useContext(PWAContext);
  const { projectId } = useParams<{ projectId: string }>();
  const { projectDevices, isLoading } = useProjectDevices({
    instance,
    projectIds: [projectId ? projectId : ""],
  });

  let deviceList: Device[] = [];
  if (project && projectDevices[project.id])
    deviceList = projectDevices[project.id];
  if (filter === "Alarms")
    deviceList = deviceList.filter(
      (device) => device.alarmStatus === AlarmStatus.Alarming,
    );
  else if (filter === "Battery")
    deviceList = deviceList.filter(
      (device) =>
        device.batteryStatus === BatteryStatus.OUT_OF_BATTERY ||
        device.batteryStatus === BatteryStatus.CRITICAL ||
        device.batteryStatus === BatteryStatus.LOW,
    );

  return (
    <Stack flex={1} style={{ overflow: "auto" }} mb="lg">
      <PwaPageHeading
        rightSection={
          <>
            {deviceList.length === 0 ? (
              <Text size="sm" c="greyText">
                No devices
              </Text>
            ) : (
              <Group gap="xs">
                <Text fw={600}>{`${deviceList.length}`}</Text>
                <Text size="sm" c="greyText">
                  devices (0 new)
                </Text>
              </Group>
            )}{" "}
          </>
        }
      >
        {filter}
      </PwaPageHeading>

      {isLoading ? (
        <Flex flex={1} align="center" justify="center">
          <Loader />
        </Flex>
      ) : (
        <Stack style={{ overflow: "auto" }}>
          <OngoingInstallationDeviceList
            devices={deviceList}
            isSnoozed={project?.isSnoozed}
            countryCode={project?.countryCode ?? ""}
          />
        </Stack>
      )}
    </Stack>
  );
}
