import { Group, Text } from "@mantine/core";

export const TwoColumnText = (props: {
  first: string;
  second: string | null | undefined;
  width?: string;
  justify?: string;
}) => {
  return (
    <>
      {props.width ? (
        <Group
          justify={props.justify ? props.justify : "space-between"}
          w={props.width}
        >
          <Text fw={500}>{props.first}:</Text>
          <Text>{props.second ? props.second : "-"}</Text>
        </Group>
      ) : (
        <Group justify={props.justify ? props.justify : "space-between"}>
          <Text fw={500}>{props.first}:</Text>
          <Text>{props.second ? props.second : "-"}</Text>
        </Group>
      )}
    </>
  );
};
