import { IconRouter } from "@tabler/icons-react";
import { Device } from "../../../models/Device";
import { getImageUrlForSign } from "../../../utils";

export const RoadSignMarkerComponent = (props: Partial<Device>) => {
  const roadSignUrl = getImageUrlForSign(props.attachmentRef);
  return (
    <>
      {!roadSignUrl ? (
        <div
          style={{
            borderRadius: "4px",
            zIndex: "5",
            position: "relative",
            width: "36px",
            height: "44px",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "2px solid #000",
            color: "#000",
          }}
        >
          <IconRouter size={"24px"} style={{ transform: "rotate(-90deg)" }} />
        </div>
      ) : (
        <img
          src={roadSignUrl}
          alt=""
          style={{
            zIndex: "5",
            position: "relative",
            maxWidth: "40px",
            maxHeight: "40px",
            width: "auto",
            height: "auto",
          }}
        />
      )}
      <div
        style={{
          width: "5px",
          height: "20px",
          position: "absolute",
          bottom: "5px",
          backgroundColor: "rgb(60, 60, 60)",
          borderRadius: "2px",
        }}
      ></div>
      <div
        style={{
          width: "25px",
          height: "15px",
          borderRadius: "2px",
          backgroundColor: "transparent",
        }}
      ></div>
    </>
  );
};
