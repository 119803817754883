import { Button, Divider, Group, Text, Title } from "@mantine/core";
import DeviceList from "../Device/DeviceList";
import { WorkZone } from "../../models/Project";
import { Device } from "../../models/Device";
import { formatDate, formatDateTime } from "../../utils";
import { useNavigate } from "react-router-dom";
import { sortDevices } from "../../services/helpers.tsx";

type Props = {
  project: WorkZone;
  projectDevices: Device[];
  depotName: string;
};

export const ProjectDrawer = ({
  project,
  projectDevices,
  depotName,
}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <Title order={2}>{project.name}</Title>
      <Text size="sm" fw={300}>
        {depotName && `${depotName},`} {project.countryCode}
      </Text>
      <Group mt={"md"} gap={"xl"}>
        <div>
          <Text size="sm" fw={500}>
            External ID
          </Text>
          <Text size="sm">{project.externalId ?? "-"}</Text>
        </div>
        <div>
          <Text size="sm" fw={500}>
            Customer
          </Text>
          <Text size="sm">
            {project.customerName ? project.customerName : "-"}
          </Text>
        </div>
        <div>
          <Text size="sm" fw={500}>
            Start
          </Text>
          <Text size="sm">{formatDate(project.startDate)}</Text>
        </div>
        <div>
          <Text size="sm" fw={500}>
            End
          </Text>
          <Text size="sm">{formatDate(project.endDate)}</Text>
        </div>
        {project && project.isClosed && (
          <div>
            <Text size="sm" fw={500}>
              Closed
            </Text>
            <Text size="sm">
              {project.closedAt ? formatDateTime(project.closedAt) : "Closed"}
            </Text>
          </div>
        )}
      </Group>
      <Divider mt="md" mb="md" />
      <Button
        variant="default"
        w="100%"
        onClick={() => navigate(`/projects/${project.id}`)}
      >
        Go to project
      </Button>
      <Divider mt="md" mb="md" />
      <DeviceList
        light={true}
        devices={sortDevices(projectDevices)}
        isSnoozed={project.isSnoozed}
        countryCode={project.countryCode} //TODO remove after projects have countries
        selectedDevice={null}
        setSelectedDevice={() => {}}
        closedProject={project.isClosed}
      />
    </>
  );
};
