import { useQuery } from "@tanstack/react-query";
import { IPublicClientApplication } from "@azure/msal-browser";
import { UserAcess } from "../../../models/UserAccess";
import { prepareHeaders } from "../../api/apiHelpers";

interface Params {
    instance: IPublicClientApplication;
    userId: string;
}
const path = `/UserAccess`
const baseUrl = import.meta.env.VITE_API_BASE_PATH;

const useUserAccesses = ({ instance, userId }: Params) => {
    const validUserId = Boolean(userId && userId.length > 0);
    const {
        data,
        isError,
        isLoading,
        isFetching
    } = useQuery<UserAcess>({
        queryKey: ["UserAccess", userId],
        enabled: validUserId,
        queryFn: async () => {
            const response = await fetch(`${baseUrl}${path}/${userId}`, {
                mode: "cors",
                method: "GET",
                headers: await prepareHeaders(instance),
            })
            if (!response.ok) {
                throw await response.json();
            }
            return response.json()
        }
    });

    return {
        userAccesses: data,
        isLoading,
        isFetching,
        isError
    }
}

export default useUserAccesses;