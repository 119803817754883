import { Group, Text } from "@mantine/core";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface Props {
  children: string;
  href: string;
  icon: ReactNode;
}

export default function NavItem({ children, href, icon }: Props) {
  return (
    <NavLink
      to={href}
      style={({ isActive }) => ({
        borderTop: "solid",
        borderColor: isActive ? "#2E6B69" : "black",
        borderTopWidth: isActive ? "2px" : "0px",
        color: isActive ? "#2E6B69" : "black",
        flex: 1,
        padding: "8px",
        textDecoration: "none",
      })}
    >
      <Group w="100%" justify="center">
        {icon}
      </Group>
      <Text size="xs" w="100%" ta="center">
        {children}
      </Text>
    </NavLink>
  );
}
