import { Group, Stack, Text, Title } from "@mantine/core";
import { DeviceListItemLight } from "./DeviceListItem/DeviceListItemLight";
import { DeviceListItem } from "./DeviceListItem/DeviceListItem";
import { Device } from "../../models/Device";
import { useNavigate } from "react-router-dom";
import { useRef, useEffect } from "react";
import { IconBuildingBroadcastTower } from "@tabler/icons-react";

type Props = {
  devices: Device[];
  light: boolean;
  projectId?: string;
  closedProject?: boolean;
  isSnoozed: boolean;
  countryCode: string;
  minWidth?: string;
  bg?: string;
  padding?: string;
  margin?: string;
  selectedDevice?: string | null;
  setSelectedDevice: (deviceId: string) => void;
};

export default function DeviceList({
  devices,
  light,
  isSnoozed,
  countryCode,
  minWidth,
  bg,
  padding,
  margin,
  selectedDevice,
  closedProject,
  setSelectedDevice,
}: Props) {
  const navigate = useNavigate();
  const deviceRefs = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    if (selectedDevice && deviceRefs.current[selectedDevice]) {
      deviceRefs.current[selectedDevice]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedDevice]);

  const workZoneDevices = devices.map((device, index) =>
    light ? (
      <div ref={(el) => (deviceRefs.current[device.id] = el)} key={`device-${index}`}>
        <DeviceListItemLight
          device={device}
          isSnoozed={isSnoozed}
          countryCode={countryCode}
          onClick={() => {
            navigate(`/devices/edit/${device.id}`);
          }}
        />
      </div>
    ) : (
      <div ref={(el) => (deviceRefs.current[device.id] = el)} key={`device-${index}`}>
        <DeviceListItem
          device={device}
          isSnoozed={isSnoozed}
          countryCode={countryCode}
          selected={device.id === selectedDevice}
          setSelectedDevice={setSelectedDevice}
          onNavigateToDevice={(deviceId) => navigate("/devices/edit/" + deviceId)}
        />
      </div>
    )
  );

  const noDevices = (
    <Stack align="center" gap={"sm"}>
      <IconBuildingBroadcastTower />
      <Title order={5}>No devices added</Title>
      <Text size="xs" c={"gray.6"}>
        {closedProject
          ? "Cannot assign devices since project is closed"
          : "Use the PWA to start assigning devices"}
      </Text>
    </Stack>
  );

  return (
    <>
      <Stack p={padding} bg={bg} m={margin} miw={minWidth} gap={"sm"}>
        <Group justify="space-between" mb={"sm"}>
          <Title fw={400} order={4}>
            Devices
          </Title>
          <Group gap={4}>
            <Text size="lg" fw={600}>
              {devices.length}
            </Text>
            <Text fw={200}>assigned</Text>
          </Group>
        </Group>
        {workZoneDevices?.length ? workZoneDevices : noDevices}
      </Stack>
    </>
  );
}