import { Device } from "./models/Device";
import QRCode from "qrcode";

const dateTimeFormatter = new Intl.DateTimeFormat("en-SE", {
  dateStyle: "short",
  timeStyle: "short",
});

const dateFormatter = new Intl.DateTimeFormat("en-SE", {
  dateStyle: "short",
});

export function formatDate(date?: Date): string {
  if (date == null) {
    return "-";
  }
  try {
    return dateFormatter.format(new Date(date));
  } catch (e) {
    return "-";
  }
}

export function formatDateTimeString(dateString?: string): string {
  if (dateString == null) {
    return "-";
  }
  try {
    return dateTimeFormatter.format(new Date(dateString));
  } catch (e) {
    return "-";
  }
}

export function formatDateTime(dateString?: Date): string {
  if (dateString == null) {
    return "-";
  }
  try {
    return dateTimeFormatter.format(new Date(dateString));
  } catch (e) {
    return "-";
  }
}

export function getEndOfDay(date: Date): Date {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999); // Set time to the very end of the day
  return endOfDay;
}

export const calcNumberOfDays = (from: Date, to: Date): number => {
  const timeDifference = to.getTime() - from.getTime();
  return timeDifference / (1000 * 60 * 60 * 24);
};

export const isBetween = (value: number, min: number, max: number): boolean => {
  return value >= min && value <= max;
};

export const panTo = (
  lat: number,
  lng: number,
  map: google.maps.Map | null,
): void => {
  map?.panTo({ lat: lat, lng: lng });
  map?.setZoom(17);
};
export const getImageUrlForSign = (
  roadSign?: string,
  addCorsParameter?: boolean,
) =>
  roadSign
    ? `https://globalplatformprodblob.blob.core.windows.net/roadsigns-20240710/${roadSign}.png${addCorsParameter ? `?r=${Math.floor(Math.random() * 100000)}` : ""}`
    : null;

export const generateQrCode = async (device: Device) => {
  const referenceId = device?.referenceId;
  const json = `{"imei": "${device?.imei}", "referenceId": "${referenceId}", "version": "1.0"}`;
  // Generate QR code
  const qrCodeDataURL = await QRCode.toDataURL(json);

  // Create canvas
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const qrImage = new Image();

  qrImage.onload = () => {
    // Set canvas dimensions
    canvas.width = qrImage.width;
    canvas.height = qrImage.height + 30;

    if (!ctx) {
      return;
    }

    // Fill the background with white
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw QR code onto canvas
    ctx.drawImage(qrImage, 0, 0);

    // Add reference ID below the QR code
    ctx.font = "20px Arial";
    ctx.textAlign = "center";
    ctx.fillStyle = "black";
    ctx.fillText(referenceId, qrImage.width / 2, qrImage.height + 20);

    // Convert canvas to PNG and trigger download
    const pngDataURL = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = pngDataURL;
    link.download = `qr_code_${device?.referenceId}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  qrImage.src = qrCodeDataURL;
};

export function getEstimatedBatteryLifetime(
  estimatedBatteryEndDate?: string,
): string {
  if (!estimatedBatteryEndDate) {
    return ""; // Handle case where estimatedBatteryEndDate is not provided
  }

  const daysRemaining = calcNumberOfDays(
    new Date(),
    new Date(formatDateTimeString(estimatedBatteryEndDate)),
  );
  if (daysRemaining <= 0) {
    return "Out of battery";
  }
  const monthsRemaining = Math.floor(daysRemaining / 30);

  if (monthsRemaining < 11) {
    if (monthsRemaining < 1) {
      return "Less than a month";
    } else {
      return `Less than ${monthsRemaining + 1} months`;
    }
  } else {
    const yearsRemaining = Math.floor(monthsRemaining / 12);
    return yearsRemaining < 1
      ? "Less than a year"
      : `Less than ${yearsRemaining + 1} years`;
  }
}

export function createDateRanges(
  startDate: Date,
  endDate: Date,
  rangeDays: number[],
  pagesDueToPageBreaks: number,
) {
  const dateRanges = [];
  let rangeIndex = 0;

  let currentStartDate = new Date(startDate.getTime());
  const originalEndDate = new Date(endDate.getTime());

  // Loop to create ranges
  while (currentStartDate <= originalEndDate) {
    // Get the current range days from the list
    const currentRangeDays = rangeDays[rangeIndex % rangeDays.length];

    // Calculate the range end by adding currentRangeDays
    let rangeEnd = new Date(currentStartDate);
    rangeEnd.setDate(rangeEnd.getDate() + currentRangeDays - 1);

    // Make sure rangeEnd doesn't go past the end date
    if (rangeEnd > originalEndDate) {
      rangeEnd = new Date(originalEndDate);
    }

    // Add range to the array
    dateRanges.push({
      start: new Date(currentStartDate),
      end: new Date(rangeEnd),
    });

    // Move startDate to the day after the rangeEnd
    currentStartDate.setDate(rangeEnd.getDate() + 1);

    // Move to the next rangeDays value
    rangeIndex++;
  }

  return dateRanges
    .map((range) => Array(pagesDueToPageBreaks).fill(range))
    .flat(1);
}
