import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Device } from "../../../models/Device";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useState } from "react";
import { useDebouncedCallback } from "@mantine/hooks";
import { APIResponseList, buildQueryString, prepareHeaders } from "../../api/apiHelpers";

const path = `${import.meta.env.VITE_API_BASE_PATH}/device/unassigned`

const useSearchUnassignedDevice = (instance: IPublicClientApplication) => {
    const pageSize = 5;
    const [queryString, setQueryString] = useState(buildQueryString({ searchTerm: "", pageSize }));

    const {
        data,
        isLoading,
        isFetching,
        isError
    } = useQuery<APIResponseList<Device>>({
        queryKey: ['Devices', 'unassigned', queryString],
        queryFn: async () => {
            const response = await fetch(`${path}${queryString}`, {
                mode: "cors",
                method: "GET",
                headers: await prepareHeaders(instance),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return {
                items: data.data,
                pageSize: data.pageSize,
                pageNumber: data.pageNumber,
                totalCount: data.totalCount,
            };
        },
        placeholderData: keepPreviousData,
    });

    const handleSearch = useDebouncedCallback(async (search: string) => {
        setQueryString(buildQueryString({ searchTerm: search, pageSize }));
    }, 500);

    return {
        devices: data?.items ?? [],
        handleSearch,
        isFetching,
        isLoading,
        isError
    }
}
export default useSearchUnassignedDevice;