import { useMsal } from "@azure/msal-react";

export const useAuthUtils = () => {
  const { instance } = useMsal();
  
  const handleLogout = () => {
    const activeAccount = instance.getActiveAccount();
    if (!activeAccount) {
      console.error("No active account found. Ensure the user is logged in.");
      return;
    }
    
    const authority =
      activeAccount.tenantId === "B2C_1_RamuddenSSO_SignUp_SignIn"
        ? "https://login.microsoftonline.com/b8989d55-a67a-4eca-9722-ec0f91f64497"
        : import.meta.env.VITE_AUTH_AUTHORITY_EXTERNAL;
    
    instance.logoutRedirect({
      authority,
      postLogoutRedirectUri: import.meta.env.VITE_AUTH_REDIRECT_URI + "/login",
    }).catch((error) => {
      console.error("Logout failed:", error);
    }).then(() => instance.setActiveAccount(null));
  };
  
  return { handleLogout };
};
