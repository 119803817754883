import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { Stack } from "@mantine/core";
import { RoadSignMarkerComponent } from "../RoadSignMarker";
import { BarrierMarkerComponent } from "../BarrierMarker";
import { Device } from "../../../../models/Device";
import {
  AlarmStatus,
  BatteryStatus,
  EquipmentType,
} from "../../../../models/enums/DeviceEnums";
import {
  IconAlertCircle,
  IconBattery2,
  IconBattery1,
  IconBattery,
} from "@tabler/icons-react";
import classes from "./DeviceMarker.module.css";

export interface Props {
  device: Device;
  map: google.maps.Map | null;
  onClick: (id: string) => void;
  isSelected?: boolean;
}

const getDeviceClass = (device: Device): string => {
  if (device.batteryStatus === BatteryStatus.OUT_OF_BATTERY) {
    return classes.deviceMarkerOutOfBattery;
  }
  if (device.alarmStatus === AlarmStatus.Alarming) {
    return classes.deviceMarkerAlarming;
  }
  if (device.batteryStatus === BatteryStatus.CRITICAL) {
    return classes.deviceMarkerCriticalBattery;
  }
  if (device.batteryStatus === BatteryStatus.LOW) {
    return classes.deviceMarkerLowBattery;
  }
  return "";
};

const alarmIconMap = {
  OutOfBattery: {
    icon: IconBattery,
    color: "white",
    backgroundColor: "rgb(158, 158, 158)",
  },
  Alarming: {
    icon: IconAlertCircle,
    color: "white",
    backgroundColor: "rgb(255, 0, 0)",
  },
  CriticalBattery: {
    icon: IconBattery1,
    color: "white",
    backgroundColor: "rgb(255, 0, 0)",
  },
  LowBattery: {
    icon: IconBattery2,
    color: "black",
    backgroundColor: "rgb(248, 220, 2)",
  },
};

const getAlarmIcon = (deviceClasses: string) => {
  const iconKey = Object.keys(alarmIconMap).find((key) =>
    deviceClasses.includes(key),
  ) as keyof typeof alarmIconMap | undefined;

  if (!iconKey) return null;

  const { icon: IconComponent, color, backgroundColor } = alarmIconMap[iconKey];
  return (
    <IconComponent
      className={`${classes.markerIcon}`}
      size={24}
      color={color}
      style={{ backgroundColor: backgroundColor }}
    />
  );
};

const DeviceMarkerComponent = ({ device, map, onClick, isSelected }: Props) => {
  const deviceClass = getDeviceClass(device);

  if (
    !device.position ||
    !device.position.coordinates ||
    device.position.coordinates.length < 2
  ) {
    return null;
  }

  return (
    <AdvancedMarker
      key={device.id}
      map={map}
      onClick={() => onClick(device.id || "")}
      position={{
        lat: device.position.coordinates[1],
        lng: device.position.coordinates[0],
      }}
    >
      <div className={classes.markerWrapper}>
        {isSelected && <div className={classes.triangleSelect} />}
        <div className={classes.markerContainer}>
          <Stack
            align="center"
            gap="4px"
            className={`${classes.deviceMarkerBase} ${deviceClass}`}
          >
            {device.currentEquipmentType === EquipmentType.Barrier ? (
              <BarrierMarkerComponent />
            ) : (
              <RoadSignMarkerComponent
                key={device.id}
                attachmentRef={device.attachmentRef}
                referenceId={device.referenceId}
              />
            )}
          </Stack>
          {getAlarmIcon(deviceClass)}
        </div>
      </div>
    </AdvancedMarker>
  );
};

export default DeviceMarkerComponent;
