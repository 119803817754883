import {
  Divider,
  Input,
  InputWrapper,
  Loader,
  SegmentedControl,
  Select,
  Stack,
} from "@mantine/core";

import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { SignRecognition } from "../../../shared/SignRecognition.tsx";
import {
  EquipmentType,
  EquipmentTypeNames,
} from "../../../models/enums/DeviceEnums.ts";
import useSearchUnassignedDevice from "../../../data/hooks/Device/useSearchUnassignedDevices.ts";
import { getSignsByCountry } from "../../../config/roadsigns.ts";
import { getDeviceInfoDescription, getDeviceInfoLabel } from "../../../copy.ts";
import { useMobileDevice } from "../../../hooks/useMobileDevice.tsx";
import { WorkZone } from "../../../models/Project.ts";
import { isNotEmpty, useForm } from "@mantine/form";
import { ConfirmButtonGroup } from "../../../shared/ConfirmButtonGroup/ConfirmButtonGroup.tsx";
import useInfProjectList from "../../../data/hooks/Project/useInfProjectListHook.ts";

interface Props {
  project?: WorkZone;
  deviceId?: string;
  onAbort?: () => void;
  onSubmit: (data: AddDeviceForm | null) => void;
}

export interface AddDeviceForm {
  deviceId: string | null;
  projectId: string | null;
  equipmentType: EquipmentType;
  attachmentRef: string | null;
  currentName: string | null;
}

export const AddDeviceInput = ({
  project,
  deviceId,
  onAbort,
  onSubmit,
}: Props) => {
  const { instance } = useMsal();
  const isMobile = useMobileDevice();
  const [searchString, setSearchString] = useState<string>("");

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      deviceId: deviceId ? deviceId : "",
      projectId: project ? project.id : "",
      equipmentType: EquipmentTypeNames[EquipmentType.Sign],
      attachmentRef: "",
      currentName: "",
    },
    validate: {
      deviceId: isNotEmpty("Device Id must be set"),
      projectId: isNotEmpty("Project Id must be set"),
      attachmentRef: (value, values) =>
        value == "" &&
        values.equipmentType == EquipmentTypeNames[EquipmentType.Sign]
          ? "You need to choose a sign"
          : null,
    },
  });

  const handleSubmit = () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      return;
    }

    const formValues = form.getValues();
    const formData: AddDeviceForm = {
      deviceId: formValues.deviceId,
      projectId: formValues.projectId,
      equipmentType:
        formValues.equipmentType == EquipmentTypeNames[EquipmentType.Sign]
          ? EquipmentType.Sign
          : EquipmentType.Barrier,
      attachmentRef:
        getSignsByCountry(country ?? "")[formValues.attachmentRef] ?? "",
      currentName: formValues.currentName,
    };
    onSubmit(formData);
    form.reset();
  };

  const {
    devices,
    handleSearch: deviceSearch,
    isFetching: devicesFetcing,
  } = useSearchUnassignedDevice(instance);
  
  const {
    projects,
    isLoading: projectsFetching,
    handleSearchChange : projectSearch,
  } = useInfProjectList(instance);
  
  const country = project
    ? project.countryCode
    : form.getValues().projectId
      ? projects.find((p) => p.id == form.getValues().projectId)?.countryCode
      : "";

  const signs = Object.keys(getSignsByCountry(country ?? ""));

  const deviceSelect = (
    <Select
      label="Add device"
      ta={"left"}
      key={form.key("deviceId")}
      {...form.getInputProps("deviceId")}
      data={Object.values(devices).map((d) => ({
        value: d.id,
        label: d.referenceId,
      }))}
      searchable
      onSearchChange={(value) => {
        setSearchString(value);
        deviceSearch(value);
      }}
      clearable
      rightSection={devicesFetcing && <Loader size={18} />}
      placeholder="Search for device by reference id"
      nothingFoundMessage={
        searchString.trim().length > 0
          ? "No device with that reference ID found..."
          : undefined
      }
    />
  );

  const projectSelect = (
    <Select
      label="Select project"
      ta={"left"}
      key={form.key("projectId")}
      {...form.getInputProps("projectId")}
      data={Object.values(projects).map((p) => ({
        value: p.id,
        label: p.name,
      }))}
      searchable
      onSearchChange={(value) => {
        setSearchString(value);
        projectSearch(value);
      }}
      clearable
      rightSection={projectsFetching && <Loader size={18} />}
      placeholder="Search for project name"
      nothingFoundMessage={
        searchString.trim().length > 0
          ? "No project with that name found..."
          : undefined
      }
    />
  );
  return (
    <>
      <form>
        <Stack maw={isMobile ? "100%" : "600px"}>
          {project && deviceSelect}
          {deviceId && projectSelect}
          <SegmentedControl
            defaultValue={form.values.equipmentType}
            data={[
              EquipmentTypeNames[EquipmentType.Sign],
              EquipmentTypeNames[EquipmentType.Barrier],
            ]}
            key={form.key("equipmentType")}
            {...form.getInputProps("equipmentType")}
            fullWidth
          />
          {form.getValues().equipmentType ==
          EquipmentTypeNames[EquipmentType.Sign] ? (
            <Stack>
              <Select
                label="Select Sign"
                ta={"left"}
                data={signs}
                key={form.key("attachmentRef")}
                {...form.getInputProps("attachmentRef")}
                searchable
                clearable
                placeholder="Search for sign by name"
                nothingFoundMessage="No sign with that name found..."
              />
              <SignRecognition
                selectedSign={form.getValues().attachmentRef}
                setSelectedSign={(id) => {
                  form.setFieldValue("attachmentRef", id);
                }}
                countryCode={country ?? ""}
              />
            </Stack>
          ) : null}
          <InputWrapper
            ta={"left"}
            label={getDeviceInfoLabel(country ?? "")}
            description={getDeviceInfoDescription(country ?? "")}
          >
            <Input
              key={form.key("currentName")}
              {...form.getInputProps("currentName")}
            />
          </InputWrapper>
          <Divider mt={"sm"} />
          <ConfirmButtonGroup
            onlyConfirm={!onAbort}
            onAbort={() => (onAbort ? (onAbort(), form.reset()) : null)}
            onConfirm={() => handleSubmit()}
          />
        </Stack>
      </form>
    </>
  );
};
