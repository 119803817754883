import { Group, Stack, Title, Text, Button } from "@mantine/core";
import { useContext, useState } from "react";
import { PWAContext } from "../../providers/PWAProvider";
import DeviceScanner from "../../components/Scanner/DeviceScanner";
import DeviceStatuses from "./components/DeviceStatuses";
import NoDeviceMessage from "./components/NoDeviceMessage";
import { IconExclamationCircle } from "@tabler/icons-react";
import PwaPageHeading from "../../components/PwaPageHeading";

export default function HealthCheck() {
  const { device } = useContext(PWAContext);
  const [showIntroMessage, setIntroMessage] = useState(true);

  return (
    <>
      {showIntroMessage ? (
        <Stack flex={1} mb="lg">
          <Stack
            flex={1}
            p="xl"
            bg="primaryGreenLight"
            justify="center"
            align="center"
            style={{ borderRadius: "24px" }}
          >
            <IconExclamationCircle size={80} style={{ marginBottom: "24px" }} />

            <Stack align="center">
              <Title ta="center">For optimal conditions to check health</Title>

              <Stack w="fit-content">
                <Text>1. Make sure the device is outdoors</Text>
                <Text>2. Wait for 24 hours</Text>
                <Text>3. Continue to health check</Text>
              </Stack>
            </Stack>
          </Stack>

          <Button size="lg" onClick={() => setIntroMessage(false)}>
            Continue
          </Button>
        </Stack>
      ) : (
        <Stack flex={1} justify="space-between" mb="lg">
          <Stack gap="sm" flex={1}>
            <Group justify="space-between">
              <PwaPageHeading label="Health Check">
                Device Health
              </PwaPageHeading>
              {device && <Text size="md">{device.referenceId}</Text>}
            </Group>

            {device ? (
              <DeviceStatuses
                latestUpdate={device.latestUpdate}
                batteryLevel={device.batteryVoltage}
                batteryStatus={device.batteryStatus}
              />
            ) : (
              <NoDeviceMessage />
            )}
          </Stack>

          <DeviceScanner isHealthCheck manualScanButtonLabel="Check" />
        </Stack>
      )}
    </>
  );
}
