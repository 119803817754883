import { Button, Center, Loader, Table } from "@mantine/core";
import { WorkZone } from "../../../models/Project";
import { Device } from "../../../models/Device";
import { useMobileDevice } from "../../../hooks/useMobileDevice";
import { formatDateTime } from "../../../utils";
import { StatusSummaryRow } from "../../Status";
import { IconArrowRight } from "@tabler/icons-react";
import classes from "./ProjectListView.module.css";
import { NoProjectsFound } from "../NoProjectsFound/NoProjectsFound";

type Props = {
  isLoading: boolean;
  projects: WorkZone[];
  depotNameMap: Map<string, string>;
  projectDevices: { [projectId: string]: Device[] };
  onClick: (project: WorkZone) => void;
};

export const ProjectListView = ({
  isLoading,
  projects,
  projectDevices,
  depotNameMap,
  onClick,
}: Props) => {
  const isMobile = useMobileDevice();
  const projectListItems = projects.map((wz) => {
    const devices = projectDevices[wz.id];
    return (
      <Table.Tr mb={"mt"} key={wz.name} onClick={() => onClick(wz)}>
        <Table.Td maw={isMobile ? "140px" : ""}>{wz.name}</Table.Td>
        <Table.Td maw={isMobile ? "140px" : ""}>
          {depotNameMap.get(wz.depotId)}
        </Table.Td>
        {isMobile ? null : (
          <>
            <Table.Td>{formatDateTime(wz.startDate)}</Table.Td>
            <Table.Td>{formatDateTime(wz.endDate)}</Table.Td>
            <Table.Td>{wz.externalId}</Table.Td>
          </>
        )}
        <Table.Td>
          <StatusSummaryRow
            devices={devices}
            isSnoozed={wz.isSnoozed}
            isClosed={wz.isClosed}
            closedAt={wz.closedAt}
            size="md"
          />
        </Table.Td>
        <Table.Td ta={"right"}>
          <Button
            c={"black"}
            variant="transparent"
            rightSection={<IconArrowRight />}
          >
            View
          </Button>
        </Table.Td>
      </Table.Tr>
    );
  });

  if (isLoading) {
    return (
      <>
        <Center mt="xl">
          <Loader />
        </Center>
      </>
    );
  }

  if (projectListItems.length == 0) {
    return <NoProjectsFound />;
  }

  return (
    <>
      <Table
        highlightOnHover
        className={classes.table}
        verticalSpacing={"sm"}
        bg={"white"}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>Depot</Table.Th>
            {isMobile ? null : (
              <>
                <Table.Th>Start</Table.Th>
                <Table.Th>End</Table.Th>
                <Table.Th>External Id</Table.Th>
              </>
            )}
            <Table.Th>Status</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody mx={"md"}>{projectListItems}</Table.Tbody>
      </Table>
    </>
  );
};
