import {
  Card,
  Text,
  Stack,
  Group,
  ActionIcon,
  Button,
  Badge,
} from "@mantine/core";
import { IconRouter, IconX } from "@tabler/icons-react";
import { formatDateTimeString, getImageUrlForSign } from "../../../utils";
import { BatteryBadge, StatusBadge } from "../../Badge";
import { AssignToProjectModal } from "../../Device/AssignToProjectModal.tsx";
import { Device } from "../../../models/Device.ts";
import {
  BatteryStatus,
  EquipmentTypeNames,
  LifeCycleStatus,
} from "../../../models/enums/DeviceEnums.ts";
import styles from "../../Badge/styles.module.css";
import { useDisclosure } from "@mantine/hooks";

interface Props {
  device: Device;
  projectName: string;
  onClose: () => void;
  onNavigateToProjectWithSelectedDevice: (
    projectId: string,
    deviceId: string,
  ) => void;
}

export const DeviceInfoCardComponent = ({
  device,
  projectName,
  onClose,
  onNavigateToProjectWithSelectedDevice,
}: Props) => {
  const [opened, { open, close }] = useDisclosure(false);
  const roadSignUrl = getImageUrlForSign(device.attachmentRef);

  return (
    <>
      <Card className="infocard" p="md" shadow="lg">
        <Stack align="flex-start" gap={"2px"}>
          <Group w="100%" wrap="nowrap">
            {roadSignUrl ? (
              <img
                src={roadSignUrl}
                alt=""
                style={{
                  width: "auto",
                  height: "auto",
                  marginRight: "8px",
                  maxWidth: "40px",
                  maxHeight: "60px",
                }}
              />
            ) : (
              <IconRouter
                size={"40px"}
                style={{ transform: "rotate(-90deg)" }}
              />
            )}
            <Stack gap="xs">
              <Text size="xl" fw={500} lh={1.3}>
                {device.currentName}
              </Text>
              <Text c="gray.6" lh={1}>
                {device.referenceId}
              </Text>
            </Stack>
            <div style={{ flexGrow: 1 }}></div>
            <ActionIcon
              mb={"auto"}
              c={"black"}
              variant="transparent"
              onClick={onClose}
            >
              <IconX />
            </ActionIcon>
          </Group>
          <Group gap={"xs"} w={"100%"} align="center">
            {device.batteryVoltage ? (
              <>
                <Text size="md" fw={700}>
                  {device.batteryVoltage}
                </Text>
                (V)
              </>
            ) : (
              <>
                <Text size="md" fw={700}></Text>
              </>
            )}
            <div style={{ flexGrow: 1 }}></div>
            {device.lifeCycleStatus != LifeCycleStatus.Unassigned ? (
              <StatusBadge size="md" status={device.alarmStatus} />
            ) : (
              <Badge
                size={"md"}
                variant={"filled"}
                className={styles.NOT_ASSIGNED}
              >
                <Group gap={6} ml={2}>
                  <Text size={"xs"} c="black" fw={500}>
                    Not assigned
                  </Text>
                </Group>
              </Badge>
            )}
            <BatteryBadge
              size="md"
              batteryStatus={
                device.batteryStatus == BatteryStatus.OK
                  ? null
                  : device.batteryStatus
              }
            />
          </Group>
          <Group w={"100%"} justify="space-between">
            <Text size="md">Project</Text>
            <Text size="md">{projectName ?? "-"}</Text>
          </Group>
          <Group w={"100%"} justify="space-between">
            <Text size="md">Latest sync</Text>
            <Text size="md">{formatDateTimeString(device.latestUpdate)}</Text>
          </Group>
          <Group w={"100%"} justify="space-between">
            <Text size="md">Equipment Type</Text>
            <Text size="md">
              {device.currentEquipmentType
                ? EquipmentTypeNames[device.currentEquipmentType]
                : "-"}
            </Text>
          </Group>
          <Group w={"100%"} justify="space-between">
            <Text size="md">Type</Text>
            <Text size="md">{device.attachmentRef ?? "-"}</Text>
          </Group>
          <Group w={"100%"} justify="flex-end" mt="md">
            {device.workZoneId ? (
              <Button
                variant="filled"
                size="xs"
                onClick={() =>
                  onNavigateToProjectWithSelectedDevice(
                    device.workZoneId,
                    device.id,
                  )
                }
              >
                Go to project
              </Button>
            ) : (
              <Button variant="outline" size="xs" onClick={() => open()}>
                Assign to project
              </Button>
            )}
          </Group>
          <AssignToProjectModal
            device={device}
            showModal={opened}
            close={close}
            onSubmit={close}
          />
        </Stack>
      </Card>
    </>
  );
};

export interface MarkerInfoProps extends Partial<Device> {
  category: string;
  projectName: string;
  closeInfoCard: () => void;
}
