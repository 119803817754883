import { ActionIcon, Button, Group, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { AddTimeFrameModal } from "../AddTimeFrame/AddTimeFrameModal";
import { IconEdit } from "@tabler/icons-react";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { TimeFrame } from "../../../models/NotificationContact";
import { DaysOfWeekNames } from "../../../models/enums/NotificationContactsEnums";

export const ProjectTimeFrame = (props: {
  timeFrame: TimeFrame;
  showAddBtn: boolean;
  onAdd: (frame: TimeFrame) => void;
  onUpdate: (existingTimeFrame: TimeFrame, updatedTimeFrame: TimeFrame) => void;
  onDelete: () => void;
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const below450 = useMediaQuery({ query: "(max-width: 450px)" });

  const weekdays =
    props.timeFrame.daysOfWeek.length == 7
      ? "All Days"
      : props.timeFrame.daysOfWeek.map((d) => DaysOfWeekNames[d]).join(", ");

  return (
    <>
      <AddTimeFrameModal
        timeFrame={props.timeFrame}
        onSubmit={(newTf) => (editModal ? props.onUpdate(props.timeFrame, newTf) : props.onAdd(newTf))}
        opened={opened}
        open={open}
        close={close}
        editModal={editModal}
        onDelete={() => props.onDelete()}
      />
      <Group align="top" wrap="nowrap" p={"sm"}>
        <div style={{ width: below450 ? "0px" : "30%" }}></div>
        <Group justify="space-between" align="top" wrap="nowrap" w={"150px"}>
          <div>
            <Text size="xs">{weekdays}</Text>
            <Text size="xs">
              {`${props.timeFrame.start.slice(0, 5)}-${props.timeFrame.end.slice(0, 5)}`}
            </Text>
          </div>
          <ActionIcon variant="outline">
            <IconEdit
              onClick={() => {
                setEditModal(true);
                open();
              }}
              size={"30px"}
            ></IconEdit>
          </ActionIcon>
        </Group>
        {props.showAddBtn ? (
          <Button
            onClick={() => {
              setEditModal(false);
              open();
            }}
            variant="outline"
            size="xs"
            flex={below450 ? 1 : undefined}
          >
            {below450 ? "Add" : "Add Time Frame"}
          </Button>
        ) : null}
      </Group>
    </>
  );
};
