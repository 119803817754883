import { Device } from "../../models/Device";
import { DeviceLog } from "../../models/DeviceLog";
import { NotificationContact } from "../../models/NotificationContact";
import { WorkZone } from "../../models/Project";
import { ProvisionData } from "../../models/ProvisionData";
import { buildQueryString, prepareHeaders } from "./apiHelpers";
import { IPublicClientApplication } from "@azure/msal-browser";

const baseUrl = import.meta.env.VITE_API_BASE_PATH;

export interface APIResponseList<T> {
  items: T[];
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  nextPage?: number;
}

type Model =
  | Device
  | DeviceLog
  | WorkZone
  | NotificationContact
  | ProvisionData;

const fetchDataList = async <T extends Model>(
  path: string,
  params: { [key: string]: any },
  instance: IPublicClientApplication,
): Promise<APIResponseList<T>> => {
  const queryString = buildQueryString(params);
  const response = await fetch(`${baseUrl}${path}${queryString}`, {
    mode: "cors",
    method: "GET",
    headers: await prepareHeaders(instance),
  });
  if (!response.ok) {
    throw await response.json();
  }
  const data = await response.json();
  return {
    items: data.data,
    pageSize: data.pageSize,
    pageNumber: data.pageNumber,
    totalPages: data.totalPages,
    nextPage:
      data.pageNumber != data.totalPages ? data.pageNumber + 1 : undefined,
  };
};

export default fetchDataList;
