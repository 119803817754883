import { Button, Text } from "@mantine/core";
import {
  IconArrowNarrowLeft,
  IconBattery2,
  IconBattery4,
} from "@tabler/icons-react";
import { Badge } from "../../../../components/Badge";
import { BADGE_STATES } from "../../../../components/Badge/Badge";
import { BatteryStatus } from "../../../../models/enums/DeviceEnums";
import { Device } from "../../../../models/Device";
import HeaderMenu from "./HeaderMenu";

interface Props {
  onBackClick: () => void;
  device: Device | null;
}

export default function SubPageHeader({ device, onBackClick }: Props) {
  return (
    <>
      <Button variant="transparent" onClick={onBackClick} px="0">
        <IconArrowNarrowLeft size="42px" color="black" />
      </Button>
      {device && <Text fw={600}>{device?.referenceId}</Text>}
      {device ? (
        <Badge
          size="sm"
          icon={
            device.batteryStatus === BatteryStatus.LOW ? (
              <IconBattery2 color="black" size={24} />
            ) : (
              <IconBattery4 color="black" size={24} />
            )
          }
          label={`${device.batteryVoltage} V`}
          state={BADGE_STATES.OK}
        />
      ) : (
        <HeaderMenu />
      )}
    </>
  );
}
