import { BADGE_STATES } from "../../components/Badge/Badge";
import { BatteryStatus } from "../../models/enums/DeviceEnums";

export default function getBadgeStateFromBatteryStatus(
  batterySatus: BatteryStatus,
) {
  const BatteryStatusBadgeStateMap = {
    [BatteryStatus.OUT_OF_BATTERY]: BADGE_STATES.OFFLINE,
    [BatteryStatus.CRITICAL]: BADGE_STATES.CRITICAL,
    [BatteryStatus.LOW]: BADGE_STATES.WARNING,
    [BatteryStatus.OK]: BADGE_STATES.OK,
  };

  if (batterySatus == null) return BADGE_STATES.OFFLINE;
  return BatteryStatusBadgeStateMap[batterySatus];
}
