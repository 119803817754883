import { useContext, useEffect, useState } from "react";
import { Button, Flex, Stack, Textarea } from "@mantine/core";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

import { PWAContext } from "../providers/PWAProvider";
import { setDeviceWorkzoneId } from "../../services/devices";
import DeviceActivationInfo from "../components/DeviceActivationInfo";
import { EquipmentType } from "../../models/enums/DeviceEnums";
import { getDeviceInfoDescription, getDeviceInfoLabel } from "../../copy";
import DeviceSummary from "../components/DeviceSummary";

export default function Summary() {
  const { instance } = useMsal();
  const {
    device,
    equipmentType,
    sign,
    project,
    setMessage,
    setDevice,
    setSign,
  } = useContext(PWAContext);
  const [currentName, setCurrentName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const connect = () => {
    if (
      project?.id &&
      device?.id &&
      (equipmentType === EquipmentType.Barrier || sign?.value)
    ) {
      setDeviceWorkzoneId(
        instance,
        project?.id,
        device?.id,
        equipmentType,
        currentName,
        sign?.value || null,
      )
        .then(() => {
          setDevice(null);
          setSign(null);
          setMessage({
            text: `Successfully paired device with ${equipmentType === EquipmentType.Barrier ? "barrier" : sign?.name}`,
            title: "Successfully assigned device",
            type: "success",
          });
          navigate("/pwa/projects/scanner");
        })
        .catch((error) => {
          setMessage({
            text: error,
            title: "Something went wrong",
            type: "error",
          });
        });
    }
  };

  return (
    <Flex h="100%" direction="column" justify="space-between" gap="md" mb="lg">
      <Stack gap="lg">
        <DeviceActivationInfo />

        <DeviceSummary
          projectName={project?.name || "-"}
          referenceID={device?.referenceId || "-"}
          equipment={{
            type: equipmentType,
            name: sign?.name,
            value: sign?.value,
          }}
        />
      </Stack>

      <Stack gap="md">
        <Textarea
          label={getDeviceInfoLabel(project?.countryCode || "")}
          placeholder={getDeviceInfoDescription(project?.countryCode || "")}
          onChange={(event) => setCurrentName(event.currentTarget.value)}
          size="md"
          value={currentName}
        />
        <Button size="xl" onClick={connect}>
          Add to project
        </Button>
      </Stack>
    </Flex>
  );
}
