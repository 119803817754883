import { Button, Container } from "@mantine/core";

import styles from "./BottomSection.module.css";

type Props = {
  disabled?: boolean;
  gradient?: boolean;
  handleClick: () => void;
  label: string;
};

export default function BottomSection({
  disabled,
  gradient,
  handleClick,
  label,
}: Props) {
  return (
    <Container className={gradient ? styles.gradient : ""}>
      <Button disabled={disabled} size="xl" onClick={handleClick} w={"100%"}>
        {label}
      </Button>
    </Container>
  );
}
