import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Group, Stack, Switch, Text } from "@mantine/core";

import { PWAContext } from "../providers/PWAProvider";
import EquipmentTypeSelector from "../components/EquipmentTypeSelector/EquipmentTypeSelector";
import useProjectDevices from "../../data/hooks/Device/useProjectDevicesHook";
import DeviceScanner from "../components/Scanner/DeviceScanner";
import PwaPageHeading from "../components/PwaPageHeading";
import HalfPanel from "../components/HalfPanel";
import useUnassignDevicesFromProject from "../../data/hooks/Device/useUnassignDevicesFromProjectHook";
import { IconTrashX } from "@tabler/icons-react";

export default function Scanner() {
  const [showMassUnassignConfirm, setShowMassUnassignConfirm] = useState(false);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { automaticDisconnect, setAutomaticDisconnect, project, setMessage } =
    useContext(PWAContext);

  const { projectDevices } = useProjectDevices({
    instance,
    projectIds: [project ? project.id : ""],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!project) navigate("/pwa/projects");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutateAsync: unassignAllDevices } =
    useUnassignDevicesFromProject(instance);

  const handleMassUnassign = async () => {
    if (!project) {
      setMessage({
        title: "Failed to remove devices",
        text: `Something went wrong when removing all devices from the project`,
        type: "error",
      });
      return;
    }
    setShowMassUnassignConfirm(false);
    unassignAllDevices({ workZoneId: project.id })
      .then(() => {
        setMessage({
          title: "Successfully removed all devices",
          text: "All devices are now removed from this project",
          type: "success",
        });
      })
      .catch(() =>
        setMessage({
          title: "Failed to remove devices",
          text: `Something went wrong when removing all devices from the project ${project?.name}`,
          type: "error",
        }),
      );
  };

  return (
    <>
      <Stack flex={1} justify="space-between" mb="lg">
        <Stack>
          <Group justify="space-between" align="end">
            <PwaPageHeading label="Project">
              {project?.name || "No project selected"}
            </PwaPageHeading>
            {projectDevices && project && (
              <Group gap="xs">
                <Text fw={600}>{projectDevices[project.id].length}</Text>
                <Text size="sm" c="#575757">
                  devices
                </Text>
              </Group>
            )}
          </Group>

          <Button
            onClick={() => setShowMassUnassignConfirm(true)}
            size="md"
            variant="default"
            leftSection={<IconTrashX />}
            disabled={!project}
          >
            Remove all devices
          </Button>
        </Stack>

        <Stack>
          <DeviceScanner
            scanLabel="Scan QR code to add or remove device from project"
            manualScanButtonLabel="Configure"
          />

          <EquipmentTypeSelector />

          <Switch
            checked={automaticDisconnect}
            label="Instant remove mode"
            onChange={(event) =>
              setAutomaticDisconnect(event.currentTarget.checked)
            }
            size="md"
          />
        </Stack>
      </Stack>

      <HalfPanel
        confirmColor="red"
        label="Remove all devices from this project"
        open={showMassUnassignConfirm}
        onClose={() => setShowMassUnassignConfirm(!showMassUnassignConfirm)}
        onConfirm={handleMassUnassign}
        title="Remove all devices"
      >
        <Text>
          Are you sure you want to remove all devices from this project?
        </Text>
      </HalfPanel>
    </>
  );
}
