import { Badge, Card, Image, Text } from "@mantine/core";
import { StatusSummaryRow } from "../Status";
import { Device } from "../../models/Device";
import { WorkZone } from "../../models/Project";
import classes from "./WorkZoneCard.module.css";
import { NoMapData } from "../../shared/NoMapData";

interface Props {
  workZone: WorkZone;
  devices: Device[];
  depotName: string;
  onClick: (id: string) => void;
}

export const WorkZoneCardComponent = ({
  workZone,
  devices,
  onClick,
  depotName,
}: Props) => {
  const landscape = "style=feature:landscape|color:0xfafafa";
  const poi = "style=feature:poi|visibility:off";
  const road = "style=feature:road|element:geometry|color:0xDBDFDF";
  const roadNames =
    "style=feature:road|element:labels.text.fill|color:0x000000";
  const water = "style=feature:water|color:0xBBD3F3";
  const mapStyles = [landscape, poi, road, roadNames, water].join("&");
  const imgWidth = "360";
  const imgHeight = "160";

  const path = workZone?.area?.coordinates?.[0]
    ? workZone.area.coordinates[0].reduce(
        (acc, point) => (acc += `|${point[1]},${point[0]}`),
        "path=color:0x6e9191|fillcolor:0x455b5b44|weight:2",
      )
    : "";
  const imageSrc = workZone.area
    ? `https://maps.googleapis.com/maps/api/staticmap?size=${imgWidth}x${imgHeight}&${path}&${mapStyles}&key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`
    : "";

  return (
    <>
      <Card
        className={classes.card}
        shadow="xs"
        padding="md"
        withBorder
        onClick={() => onClick(workZone.id)}
        data-testid="wz-card"
        w={`${imgWidth}px`}
      >
        <Card.Section>
          {workZone.isDemoProject && (
            <Badge
              className="demo-badge"
              size="sm"
              style={{
                position: "absolute",
                top: 10,
                left: 10,
                zIndex: 103,
              }}
            >
              Demo
            </Badge>
          )}
          {workZone.area ? (
            <Image src={imageSrc} height={160} alt="Workzone Image" />
          ) : (
            <NoMapData height={`${imgHeight}px`} />
          )}
        </Card.Section>

        <Text mt={"md"} fw={500} truncate="end">
          {workZone.name}
        </Text>
        <Text size="xs" mb={"sm"} fw={300} truncate="end">
          {depotName && `${depotName},`} {workZone.countryCode}
        </Text>
        <StatusSummaryRow
          isSnoozed={workZone.isSnoozed}
          snoozedUntil={workZone.snoozedUntil}
          isClosed={workZone.isClosed}
          closedAt={workZone.closedAt}
          size="md"
          devices={devices}
        />
      </Card>
    </>
  );
};
