import {
  Card,
  Button,
  Stack,
  Space,
  Group,
  ActionIcon,
  Title,
  Text,
} from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { formatDateTime } from "../../../utils";
import { useMap } from "@vis.gl/react-google-maps";
import { StatusSummaryRow } from "../../Status";
import { Device } from "../../../models/Device";
import { WorkZone } from "../../../models/Project";

interface Props {
  workZone: WorkZone;
  devices: Device[];
  depotName: string;
  onClose: () => void;
  panTo: (lat: number, lng: number, map: google.maps.Map | null) => void;
  onNavigateToProject: (projectId: string) => void;
}

export const WorkzoneInfoCardComponent = ({
  workZone,
  devices,
  depotName,
  onClose,
  panTo,
  onNavigateToProject,
}: Props) => {
  const map = useMap();
  const workZoneMiddlePoint = workZone.center;

  return (
    <>
      <Card className="infocard" p="md" shadow="lg">
        <Stack align="flex-start" gap={"2px"}>
          <Group w={"100%"} wrap="nowrap" justify="space-between" align="top">
            <Title order={2}>{workZone.name}</Title>
            <ActionIcon c={"black"} variant="transparent" onClick={onClose}>
              <IconX></IconX>
            </ActionIcon>
          </Group>
          <Text size="sm" mb={"sm"} fw={300} truncate="end">
            {depotName && `${depotName},`} {workZone.countryCode}
          </Text>
          <StatusSummaryRow devices={devices} />
          <Group w={"100%"} justify="space-between">
            <Text size="md">Customer</Text>
            <Text size="md">{workZone.customerName ?? "-"}</Text>
          </Group>
          <Group w={"100%"} justify="space-between">
            <Text size="md">Type</Text>
            <Text size="md">
              {workZone.startDate ? formatDateTime(workZone.startDate) : "-"}
            </Text>
          </Group>
          <Space h="sm" />
        </Stack>
        {workZoneMiddlePoint && (
          <Group w={"100%"} justify="flex-end" mt="md" gap={"xs"}>
            <Button
              variant={"outline"}
              aria-label="go to project"
              onClick={() => onNavigateToProject(workZone.id)}
            >
              Go to project
            </Button>
            <Button
              aria-label="zoom to project"
              onClick={() =>
                panTo(
                  workZoneMiddlePoint.coordinates[1],
                  workZoneMiddlePoint.coordinates[0],
                  map,
                )
              }
            >
              Zoom to project
            </Button>
          </Group>
        )}
      </Card>
    </>
  );
};
