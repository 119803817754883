import { IPublicClientApplication } from "@azure/msal-browser";
import { useInfiniteQuery, InfiniteData, QueryKey } from "@tanstack/react-query";
import { APIResponseList, buildQueryString, prepareHeaders } from "../../api/apiHelpers";
import { ProjectCommandLog } from "../../../models/ProjectHistoryLog";

const path = `${import.meta.env.VITE_API_BASE_PATH}/workzone/command-logs`;

interface Params {
    projectId: string;
    instance: IPublicClientApplication
}

const useProjectCommandLogs = ({ instance, projectId }: Params) => {
    const {
        data,
        isLoading,
        isFetching,
        isError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery<APIResponseList<ProjectCommandLog>, Error, InfiniteData<APIResponseList<ProjectCommandLog>>, QueryKey, number>({
        queryKey: ["project-command-logs", { projectId }],
        queryFn: async ({ pageParam }) => {
            const queryString = buildQueryString({ pageSize: 1000, pageNumber: pageParam, projectId });
            const response = await fetch(`${path}${queryString}`, {
                mode: "cors",
                method: "GET",
                headers: await prepareHeaders(instance),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return {
                items: data.data,
                pageSize: data.pageSize,
                pageNumber: data.pageNumber,
                totalCount: data.totalCount,
            };
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, _allPages, lastPageParam) => {
            if (lastPage.items.length === 0) {
                return undefined
            }

            return lastPageParam + 1
        },
    });

    const projectCommandLogs = data?.pages.flatMap((page) => page.items) ?? [];

    return {
        projectCommandLogs,
        isLoading,
        isFetching,
        isError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    };
};

export default useProjectCommandLogs;