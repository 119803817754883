import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { WorkZone } from "../../../models/Project";
import { Tooltip } from "@mantine/core";

interface Props {
  workZone: WorkZone;
  map: google.maps.Map | null;
  onClick: (id: string) => void;
}

export const DotMarker = ({ workZone, map, onClick }: Props) => {
  const workZoneMiddlePoint = workZone.center;
  return (
    <>
      <AdvancedMarker
        key={workZone.id}
        map={map}
        onClick={() => onClick(workZone.id ? workZone.id : "")}
        position={
          workZoneMiddlePoint
            ? {
                lat: workZoneMiddlePoint.coordinates[1],
                lng: workZoneMiddlePoint.coordinates[0],
              }
            : null
        }
      >
        <Tooltip label={workZone.name}>
          <div
            style={{
              height: "12px",
              width: "12px",
              borderRadius: "50%",
              background: "#003D8A",
              border: "1px solid white",
            }}
          ></div>
        </Tooltip>
      </AdvancedMarker>
    </>
  );
};
