import React from "react";
import { msalConfig } from "./auth.ts";
import { PublicClientApplication } from "@azure/msal-browser";
import App from "./App.tsx";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.ts";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { handleError } from "./data/errorHandler";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      handleError(error);
    },
  }),
});

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    console.log(
      "No active account found, but there are accounts available. Setting the first account as active account.",
    );
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.handleRedirectPromise().then(() => {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  });

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  const container = document.getElementById("root");
  if (!container) {
    throw new Error('No root element found with id "root"');
  }

  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Router>
        <QueryClientProvider client={queryClient}>
          <App pca={msalInstance} />
        </QueryClientProvider>
      </Router>
    </React.StrictMode>,
  );
});

// Register the service worker
serviceWorkerRegistration.register();
