import DeviceList from "../../Device/DeviceList";
import {
  Button,
  Drawer,
  Group,
  Paper,
  Pill,
  ScrollArea,
  Text,
} from "@mantine/core";
import LightMap from "../../Map/LightMap";
import { getWorkZoneMiddlePoint } from "../../../services/helpers";
import {
  AdvancedMarker,
  Pin,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import DeviceMarkerComponent from "../../Map/Markers/DeviceMaker/DeviceMarker.tsx";
import { useEffect, useState } from "react";
import { useMobileDevice } from "../../../hooks/useMobileDevice";
import classes from "./ProjectOverview.module.css";
import {
  ManualCenterPoint,
  EditAndCreateProjectModal,
} from "../CreateProjectModal/EditAndCreateProjectModal";
import { useMsal } from "@azure/msal-react";
import { LocationMode } from "../../../shared/types.tsx";
import { WorkZone } from "../../../models/Project.ts";
import { Device } from "../../../models/Device.ts";
import useSetManualCenter from "../../../data/hooks/Project/useSetManualCenterHook.ts";
import { Point } from "geojson";
import { IconArrowLeft } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { NoMapData } from "../../../shared/NoMapData.tsx";

interface Props {
  workZone: WorkZone;
  devices: Device[];
  initialSelectedDevice?: string | null;
}

export const ProjectOverview = ({
  workZone,
  devices,
  initialSelectedDevice,
}: Props) => {
  const map = useMap();
  const { instance } = useMsal();
  const isMobile = useMobileDevice();
  const [openModal, setOpenModal] = useState(false);
  const middlePoint = getWorkZoneMiddlePoint(workZone);
  const [selectedDevice, setSelectedDevice] = useState<string | null>(
    initialSelectedDevice ?? null,
  );
  const [opened, { open, close }] = useDisclosure(false);
  const defaultCenter = middlePoint
    ? { lng: middlePoint.coordinates[1], lat: middlePoint.coordinates[0] }
    : undefined;
  const markers = devices.map((d) => (
    <DeviceMarkerComponent
      device={d}
      map={map}
      onClick={() => {
        handleDeviceSelectedClick(d.id);
      }}
      key={d.id}
      isSelected={d.id === selectedDevice}
    />
  ));
  const pin = (
    <AdvancedMarker
      draggable
      position={
        workZone.center
          ? {
              lat: workZone.center?.coordinates[1],
              lng: workZone.center?.coordinates[0],
            }
          : null
      }
    >
      <Pin background={"#173232"} borderColor={"#173232"} glyphColor={"#fff"} />
    </AdvancedMarker>
  );
  const [address, setAddress] = useState<string | null>();
  const { mutate: setManualCenterPoint } = useSetManualCenter(instance);

  const handleDeviceSelectedClick = (id: string) => {
    if (id === selectedDevice) {
      setSelectedDevice(null);
      return;
    }
    setSelectedDevice(id);
  };

  const handleUpdateProjectCenter = (
    centerPoint: ManualCenterPoint | null,
    mode: LocationMode,
  ) => {
    if (mode == LocationMode.Manual) {
      if (!centerPoint) {
        return;
      }
      const center: Point = {
        type: "Point",
        coordinates: [centerPoint.position.lng, centerPoint.position.lat],
      };
      setManualCenterPoint({ id: workZone.id, center: center, isManual: true });
    } else if (mode == LocationMode.Automatic) {
      setManualCenterPoint({ id: workZone.id, center: null, isManual: false });
    }
  };

  const geoLib = useMapsLibrary("geocoding");
  useEffect(() => {
    if (!geoLib || !middlePoint) {
      return;
    }
    const GeoCoder = new geoLib.Geocoder();
    const request: google.maps.GeocoderRequest = {
      location: {
        lng: middlePoint.coordinates[1],
        lat: middlePoint.coordinates[0],
      },
    };

    GeoCoder.geocode(request).then((res) => {
      if (res.results[0]) {
        setAddress(res.results[0].formatted_address);
      } else {
        setAddress("No address found");
      }
    });
  }, [geoLib, middlePoint]);

  const geoLocationBox = (
    <Paper
      className={isMobile ? classes.fixedBottom : classes.fixedLeft}
      w={isMobile ? "100%" : "300px"}
      p={"md"}
      shadow="md"
    >
      {middlePoint ? (
        <>
          <Text fw={600}>{address}</Text>
          <Text opacity={0.5} fw={200} size="xs">
            {middlePoint?.coordinates[0]}"N, {middlePoint?.coordinates[1]}"E
          </Text>
        </>
      ) : (
        <Text opacity={0.5} fw={200} size="xs">
          Project center point will be calculated from devices GPS data
        </Text>
      )}
      <Group mt={"sm"} justify="space-between">
        <Pill>{workZone.manualCenter ? "Manual" : "Automatic"}</Pill>
        <Button onClick={() => setOpenModal(true)} variant="transparent">
          Edit
        </Button>
      </Group>
    </Paper>
  );

  return (
    <>
      {isMobile && (
        <Button
          bg={"white"}
          c={"black"}
          className={classes.fixedBtn}
          onClick={open}
          m="md"
          leftSection={<IconArrowLeft />}
        >
          Open device list
        </Button>
      )}
      <Group gap={"xs"} align="top" wrap="nowrap">
        {!isMobile ? (
          <ScrollArea miw={"30%"} h={"calc(100vh - 290px)"}>
            <DeviceList
              light={false}
              padding="md"
              margin="md"
              bg="white"
              devices={devices}
              isSnoozed={workZone.isSnoozed}
              closedProject={workZone.isClosed}
              countryCode={workZone.countryCode}
              selectedDevice={selectedDevice}
              setSelectedDevice={handleDeviceSelectedClick}
            />
          </ScrollArea>
        ) : (
          <Drawer opened={opened} onClose={close} size={"100%"}>
            <DeviceList
              light={false}
              bg="white"
              devices={devices}
              isSnoozed={workZone.isSnoozed}
              countryCode={workZone.countryCode}
              selectedDevice={selectedDevice}
              setSelectedDevice={handleDeviceSelectedClick}
            />
          </Drawer>
        )}
        {workZone.isClosed ? (
          <NoMapData height={"calc(100vh - 290px)"} />
        ) : (
          <LightMap
            height="calc(100vh - 290px)"
            defaultCenter={defaultCenter}
            defaultZoom={14}
            markers={markers.length ? markers : [pin]}
            disableDefaultUI={false}
            selectedDevice={devices.find((d) => d.id === selectedDevice)}
          />
        )}
      </Group>
      {workZone.isClosed ? null : geoLocationBox}
      <EditAndCreateProjectModal
        isOpen={openModal}
        close={() => setOpenModal(false)}
        onChange={(point, mode) => {
          handleUpdateProjectCenter(point, mode);
        }}
        onSubmit={() => {}}
      />
    </>
  );
};
