import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { Paper, Text, Tooltip } from "@mantine/core";
import { StatusSummaryRow } from "../../Status";
import { WorkZone } from "../../../models/Project";
import { Device } from "../../../models/Device";

interface Props {
  workZone: WorkZone;
  devices: Device[];
  map: google.maps.Map | null;
  showDotMarker: boolean;
  onClick: (id: string) => void;
}

export const ProjectMarkerComponent = ({
  workZone,
  devices,
  map,
  showDotMarker,
  onClick,
}: Props) => {
  const workZoneMiddlePoint = workZone.center;
  return (
    <>
      <AdvancedMarker
        key={workZone.id}
        map={map}
        onClick={() => onClick(workZone.id ? workZone.id : "")}
        className={showDotMarker ? "" : "customMarker"}
        position={
          workZoneMiddlePoint
            ? {
                lat: workZoneMiddlePoint.coordinates[1],
                lng: workZoneMiddlePoint.coordinates[0],
              }
            : null
        }
      >
        {showDotMarker ? (
          <Tooltip label={workZone.name}>
            <div
              style={{
                height: "12px",
                width: "12px",
                borderRadius: "50%",
                background: "#003D8A",
                border: "1px solid white",
              }}
            ></div>
          </Tooltip>
        ) : (
          <Paper p={"sm"} shadow="lg" px="sm">
            <Text fw={500}>{workZone.name}</Text>
            <StatusSummaryRow size="md" devices={devices} />
          </Paper>
        )}
      </AdvancedMarker>
    </>
  );
};
