import { Stack } from "@mantine/core";
import Row from "./Row/Row";
import { EquipmentType } from "../../models/enums/DeviceEnums";
import PwaPageHeading from "./PwaPageHeading";

interface Props {
  projectName: string;
  referenceID: string;
  equipment?: { type: EquipmentType; name?: string; value?: string };
  additionalInfo?: string;
}

export default function DeviceSummary({
  projectName,
  referenceID,
  equipment,
  additionalInfo,
}: Props) {
  return (
    <Stack gap="sm">
      <PwaPageHeading>Device summary</PwaPageHeading>

      <Stack
        style={{
          border: "1px solid #8E8E8E",
          borderRadius: 4,
          overflow: "auto",
        }}
        p={24}
      >
        <Row label="Project" text={projectName} />
        {equipment && (
          <Row
            label="Equipment type"
            text={EquipmentType[equipment?.type]}
            signCode={equipment?.value}
          />
        )}
        {additionalInfo && (
          <Row label="Additional info" text={additionalInfo} />
        )}
        <Row label="Reference ID" text={referenceID} />
      </Stack>
    </Stack>
  );
}
