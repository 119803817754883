import { useQuery } from "@tanstack/react-query";
import { NotificationContact } from "../../../models/NotificationContact";
import fetchDataList, { APIResponseList } from "../../api/fetchDataList";
import { IPublicClientApplication } from "@azure/msal-browser";

interface Params {
    instance: IPublicClientApplication;
    projectId: string;
}

const path = `/notificationContact/paginated`;

const useGetNotificationContactsInWorkZone = ({ instance, projectId }: Params) => {
    const pageNumber = 1;
    const pageSize = 1000;

    const {
        data,
        isLoading,
        isError
    } = useQuery<APIResponseList<NotificationContact>>({
        queryKey: ["notification-contacts", { workZoneId: projectId }],
        queryFn: () => fetchDataList(path, { pageNumber, pageSize, ...{ workZoneId: projectId } }, instance),
        enabled: !!projectId
    });

    const notificationContacts = data?.items || [];

    return {
        notificationContacts,
        isLoading,
        isError
    }
}

export default useGetNotificationContactsInWorkZone;