import { BatteryBadge, SnoozeBadge, StatusBadge } from "../Badge";
import { AlarmStatus, BatteryStatus } from "../../models/enums/DeviceEnums";

type Props = {
  alarmStatus: AlarmStatus;
  batteryStatus: BatteryStatus;
  isSnoozed?: boolean;
  alarmCount?: number;
  batteryCount?: number;
  size?: "md" | "lg";
};

export default function StatusGroup({
  alarmStatus,
  batteryStatus,
  isSnoozed,
  size = "lg",
  alarmCount,
  batteryCount,
}: Props) {
  if (isSnoozed) return <SnoozeBadge />;

  {
    return (
      <>
        {batteryStatus !== BatteryStatus.OUT_OF_BATTERY && (
          <StatusBadge
            status={alarmStatus}
            number={alarmStatus !== AlarmStatus.OK ? alarmCount : undefined}
            size={alarmStatus !== AlarmStatus.OK ? size : undefined}
          />)}
        {batteryStatus !== BatteryStatus.OK && (
          <BatteryBadge
            number={batteryCount}
            size={size}
            batteryStatus={batteryStatus}
          />
        )}
      </>
    );
  }
}
