import jsPDF from "jspdf";
import autoTable, { CellHookData, CellInput } from 'jspdf-autotable';
import { DeviceEntry, DeviceRow } from "../../../models/SupervisionReport";
import { AlarmStatus, EquipmentType } from "../../../models/enums/DeviceEnums";
import circleDotted from "./StatusIcons/circle-dotted.png";
import circleAlert from "./StatusIcons/alert-circle.png";
import circleCheck from "./StatusIcons/circle-check.png";
import logo from "../../../assets/primLogoYellow.png";
import { createDateRanges, formatDate } from "../../../utils";

const INCREASE_ICON_WITH_40_PERCENT = 1.4;
const TIMESTAMP_CELL_WIDTH = 12;
const TEXT_FIELDS_CELL_WIDTH = 20;
const FONT_SIZE = 8;
const DEEP_GREEN_7 = '#455b5b';
const PAGE_COLUMS_MAX_LIMT = 18;
const PAGE_ROWS_MAX_LIMT = 24;
const FIXED_COLUMNS = 3

export type PdfProps = {
  timeStamps: string[]
  intervalInHours: number;
  rows: DeviceRow[];
  projectName: string;
  exportStartDate: Date;
  exportEndDate: Date;
  showSnoozed: boolean;
  imageMap: Map<string, HTMLImageElement>;
}

enum LogStatus {
  NoLog = 0
}

export const createPDF = ({ timeStamps, intervalInHours, rows, projectName, exportStartDate, exportEndDate, showSnoozed, imageMap }: PdfProps) => {
  return new Promise(resolve => {
    const startDateString = formatDate(exportStartDate)
    const endDateString = formatDate(exportEndDate)
    const timeStampHeaders = timeStamps.map((timestamp) => {
      const day = new Date(timestamp).toLocaleDateString("en-US", { weekday: "short" })
      const start = formatTime(timestamp);
      const end = formatTime(getNextTimestamp(timestamp, intervalInHours))
      return `${day} ${start} ${end}`
    })

    const allRows = rows.map(row => {
      const talkInData = timeStamps.map((_, index) => {
        const entry = row.entries[index];
        return getTemporaryTextStatus(entry, showSnoozed)
      })
      const equipmentType = row.currentEquipmentType == EquipmentType.Barrier ? EquipmentType.Barrier.toString() : EquipmentType.Sign.toString()
      const attachmentRef = row.attachmentRef ? row.attachmentRef : equipmentType
      return [row.referenceId, row.name, attachmentRef, talkInData].flat()
    })

    const numbOfTalkinColumns = allRows[0].length - FIXED_COLUMNS
    const columsInOneDay = 24 / intervalInHours
    const pageBreakPages = Math.ceil(rows.length / PAGE_ROWS_MAX_LIMT);

    const headers = [
      'Reference Id',
      'Name',
      'Attachment',
      ...timeStampHeaders
    ]

    const doc = new jsPDF({ orientation: 'landscape' });
    const pageWidth = doc.internal.pageSize.getWidth();

    function createDaysOnEachPagesArray(columns: number, limit: number) {
      const numbOfFullPages = (columns - columns % limit) / limit
      const nonFullPage = columns % limit

      const columnsOnEachPageArray = Array(numbOfFullPages).fill(limit)
      if (nonFullPage) columnsOnEachPageArray.push(nonFullPage)

      return columnsOnEachPageArray.map((v) => Math.floor(v / columsInOneDay));
    }

    const daysOnEachPageArray = createDaysOnEachPagesArray(numbOfTalkinColumns, PAGE_COLUMS_MAX_LIMT)
    const pagesDateSpans = createDateRanges(exportStartDate, exportEndDate, daysOnEachPageArray, pageBreakPages)

    function addHeader(position: { x: number, y: number }) {
      const heading = `${projectName}, ${startDateString} - ${endDateString}`
      doc.setFontSize(16);
      doc.text(heading, position.x, position.y);
      doc.addImage(logo, 'PNG', (pageWidth - 31 - position.x), 2, 30, 16);
    }

    function addSubHeader(position: { x: number, y: number }, pageNumber: number) {
      const subHeading = `Showing ${formatDate(pagesDateSpans[pageNumber - 1].start)} to ${formatDate(pagesDateSpans[pageNumber - 1].end)}`
      doc.setFontSize(10)
      doc.text(subHeading, position.x, position.y);
    }

    function addFooter(position: { x: number, y: number }, pageNumber: number) {
      doc.setFontSize(10);
      doc.text(`Page ${pageNumber}`, position.x, position.y);
    }

    function addDocumentName() {
      const documentTitle = `${projectName} ${startDateString} ${endDateString}`
      doc.save(`${documentTitle.split(' ').join('_')}.pdf`);
    }

    function addStatusIconToCell(data: CellHookData) {
      if (data.column.index > 2 && data.cell.section == 'body') {
        const img = new Image
        img.src = getExportIcon(data.cell.raw)
        const dim = (data.cell.height - data.cell.padding('vertical')) * INCREASE_ICON_WITH_40_PERCENT;
        const x = data.cell.x + data.cell.width / 2 - dim / 2
        const y = data.cell.y + data.cell.height / 2 - dim / 2
        doc.addImage(img, x, y, dim, dim)
      }
    }

    function removeTemporaryStatusId(data: CellHookData) {
      if (data.column.index > 2 && data.cell.section == 'body') {
        data.cell.text[0] = ""
      }
    }

    //Autotable is synchronous, if any async action need to take place do that before and add it as input to this file
    autoTable(doc, {
      head: [headers],
      body: allRows,
      margin: {
        left: 8,
        right: 8,
        top: 20
      },
      headStyles: {
        cellWidth: TIMESTAMP_CELL_WIDTH,
        valign: 'middle',
        halign: 'center',
        fillColor: DEEP_GREEN_7,
        fontStyle: 'normal',
        fontSize: FONT_SIZE,
      },
      columnStyles: {
        0: { cellWidth: TEXT_FIELDS_CELL_WIDTH },
        1: { cellWidth: TEXT_FIELDS_CELL_WIDTH + 3.5, overflow: 'ellipsize' },
        2: { cellWidth: TEXT_FIELDS_CELL_WIDTH },
      },
      bodyStyles: {
        valign: 'middle',
        halign: 'center',
        fontSize: FONT_SIZE
      },
      horizontalPageBreak: true,
      horizontalPageBreakRepeat: [0, 1, 2],
      didDrawCell: (data) => {
        if (data.column.index == 2 && data.cell.section == 'body' && data.cell.raw) {
          const image = imageMap.get(data.cell.raw as string)
          if (!image) return
          const ratio = image.naturalWidth / image.naturalHeight
          const dim = (data.cell.height - data.cell.padding('vertical')) * INCREASE_ICON_WITH_40_PERCENT;
          const x = data.cell.x + data.cell.width / 2 - dim / 2
          const y = data.cell.y + data.cell.height / 2 - dim / ratio / 2

          if (!isNaN(x) && !isNaN(y) && !isNaN(dim) && !isNaN(dim / ratio)) {
            doc.addImage(image, x, y, dim, dim / ratio)
          } else {
            console.error('Invalid dimensions for attachment image');
          }
        }
        addStatusIconToCell(data)
      },
      didParseCell: (data) => {
        removeTemporaryStatusId(data)
        if (data.column.index == 2 && data.cell.section == 'body') {
          data.cell.text[0] = ""
        }
      },
      didDrawPage: (data) => {
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        addHeader({ x: data.settings.margin.left, y: 12 })
        addSubHeader({ x: data.settings.margin.left, y: 18 }, data.pageNumber)
        addFooter({ x: (pageWidth - 12 - data.settings.margin.left), y: pageHeight - 4 }, data.pageNumber)
      }
    });
    addDocumentName()
    resolve('resolved')
  })
}


function formatTime(dateString: string) {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
}

function getNextTimestamp(dateString: string, intervalInHours: number | null) {
  const date = new Date(dateString);
  date.setHours(date.getHours() + (intervalInHours ?? 0));
  return date.toISOString();
}

const getTemporaryTextStatus = (entry: DeviceEntry, showSnoozed: boolean): AlarmStatus | LogStatus => {
  const logsToShow = entry?.logs?.length
    ? showSnoozed
      ? entry.logs
      : entry.logs.filter((dl) => !dl.workZoneSnoozed)
    : [];

  if (logsToShow.length === 0) return LogStatus.NoLog;
  if (logsToShow.some((log) => log.alarmStatus === AlarmStatus.Alarming))
    return AlarmStatus.Alarming;
  return AlarmStatus.OK;

};

const getExportIcon = (id: CellInput) => {
  if (id == AlarmStatus.OK) return circleCheck;
  if (id == AlarmStatus.Alarming) return circleAlert;
  return circleDotted
}