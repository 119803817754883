import {
  Stack,
  Card,
  Group,
  Text,
  Divider,
  Alert,
} from "@mantine/core";
import { BatteryBadge, StatusBadge } from "../Badge";
import { useMsal } from "@azure/msal-react";
import { BatteryStatus, EquipmentTypeNames } from "../../models/enums/DeviceEnums";
import useInfDeviceLogList from "../../data/hooks/DeviceLog/useInfDeviceLogListHook";
import React from "react";

interface DeviceLogsTableProps {
  deviceId: string;
}

const DeviceLogsTable = (props: DeviceLogsTableProps) => {
  const { instance } = useMsal();
  const {
    deviceLogs,
    isError: error,
  } = useInfDeviceLogList({ instance, deviceId: props.deviceId });

  return (
    <div>
      <Stack gap="md">
        {error ? (
          <Alert title="Error" color="red">
            {error.toString()}
          </Alert>
        ) : (
          deviceLogs.map((log, index) => (
            <Card key={index} shadow="sm" padding="sm">
              <Group
                p="apart"
                style={{
                  width: "100%",
                  marginBottom: 5,
                  justifyContent: "space-between",
                }}
              >
                <Text>{new Date(log.timestamp).toLocaleString()}</Text>
                <Group gap={"xs"}>
                  <StatusBadge status={log.alarmStatus} />
                  <BatteryBadge
                    batteryStatus={
                      log.batteryStatus == BatteryStatus.OK
                        ? null
                        : log.batteryStatus
                    }
                  />
                </Group>
              </Group>
              <Divider />
              <Text m="xs" size="sm">
                <strong>Project: </strong>
                {log.workZoneName ?? "-"}
              </Text>
              <Group p="apart" gap="m" m="xs">
                <Text size="sm">
                  <strong>Battery (V): </strong>
                  {log.batteryVoltage ?? "-"}
                </Text>
                <Text size="sm">
                  <strong>Type: </strong>
                  {EquipmentTypeNames[log.currentEquipmentType] ?? "-"}
                </Text>
                <Text size="sm">
                  <strong>Attached to: </strong>
                  {log.attachmentRef ?? "-"}
                </Text>
              </Group>
            </Card>
          ))
        )}
      </Stack>
    </div>
  );
}

export default React.memo(DeviceLogsTable);