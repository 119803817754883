import { Image } from "@mantine/core";
import logo from "../../../../assets/ramudden_logo.svg";
import HeaderMenu from "./HeaderMenu";

export default function MainPageHeader() {
  return (
    <>
      <Image src={logo} h="42px" ml="-14px" />
      <HeaderMenu />
    </>
  );
}
