import { InfiniteData, useInfiniteQuery, QueryKey } from "@tanstack/react-query";
import { useState } from "react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { defaultBulkProvisionFilters } from "../../filters";
import { useDebouncedCallback } from "@mantine/hooks";
import useInfiniteScroll from "../useInfiniteScroll";
import QueryKeys from "../../queryKeys";
import { ProvisionData } from "../../../models/ProvisionData";
import { APIResponseList, buildQueryString, prepareHeaders } from "../../api/apiHelpers";

const path = `${import.meta.env.VITE_API_BASE_PATH}/DeviceProvisioning`;

const useInfBulkProvisionListHook = (instance: IPublicClientApplication) => {
    const [search, setSearch] = useState("");
    const [filters, setFilters] = useState(defaultBulkProvisionFilters);
    const pageSize = 20;

    const {
        data,
        isLoading,
        isError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery<APIResponseList<ProvisionData>, Error, InfiniteData<APIResponseList<ProvisionData>>, QueryKey, number>({
        queryKey: QueryKeys.Devices.infiniteList(filters),
        queryFn: async ({ pageParam }) => {
            const queryString = buildQueryString({ ...filters, pageSize, pageNumber: pageParam });
            const response = await fetch(`${path}${queryString}`, {
                mode: "cors",
                method: "GET",
                headers: await prepareHeaders(instance),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return {
                items: data.data,
                pageSize: data.pageSize,
                pageNumber: data.pageNumber,
                totalCount: data.totalCount,
            };
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
            const nextPage = lastPage.pageNumber < lastPage.totalCount ? lastPage.pageNumber + 1 : undefined;
            return nextPage;
        },
        refetchInterval: 60000,
    });

    useInfiniteScroll(fetchNextPage, isLoading, isFetchingNextPage, hasNextPage);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        handleSearch(event.target.value);
    };

    const handleSearch = useDebouncedCallback(async (search: string) => {
        setFilters({ searchTerm: search });
    }, 500);

    const onSerachSpecific = (searchString: string) => {
        setSearch(searchString)
        setFilters({ searchTerm: searchString })
    }

    const bulkProvisions = data?.pages.flatMap((page) => page.items) ?? [];

    return {
        bulkProvisions,
        isLoading,
        isError,
        search,
        handleSearchChange,
        onSerachSpecific,
        fetchNextPage,
        setFilters,
        setSearch,
    };


};

export default useInfBulkProvisionListHook;