export const getDeviceInfoLabel = (countryCode: string) => {
    switch (countryCode) {
        case "GB":
            return "Marker post / Additional information";
        default:
            return "Additional information";
    }
}

export const getDeviceInfoDescription = (countryCode: string) => {
    switch (countryCode) {
        case "GB":
            return "Marker post or information regarding placement or name of device (optional)";
        default:
            return "Information regarding placement or name of device (optional)";
    }
}