import { useQuery } from "@tanstack/react-query";
import { Device } from "../../../models/Device";
import fetchDataById, { APIResponsePoint } from "../../api/fetchDataById";
import QueryKeys from "../../queryKeys";
import { IPublicClientApplication } from "@azure/msal-browser";

interface Params {
    instance: IPublicClientApplication;
    deviceId: string;
}
const path = `/device`

const useDevice = ({ instance, deviceId }: Params) => {
    const {
        data,
        isLoading,
        isError
    } = useQuery<APIResponsePoint<Device>>({
        queryKey: QueryKeys.Devices.detail(deviceId),
        queryFn: () => fetchDataById(path, deviceId, instance),
        refetchInterval: 60000,
    });

    return {
        device: data?.item,
        isLoading,
        isError
    }
}

export default useDevice;