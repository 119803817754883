import { IPublicClientApplication } from "@azure/msal-browser";
import { prepareHeaders } from "../../api/apiHelpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface Params {
    NotificationContactId: string;
}

const path = `${import.meta.env.VITE_API_BASE_PATH}/notificationContact/toggle-battery-alarm-notification-enabled`;

const useToggleBatteryNotifications = (instance: IPublicClientApplication) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isPending,
    } = useMutation({
        mutationFn: async (params: Params) => {
            return await fetch(path, {
                mode: 'cors',
                method: 'POST',
                headers: await prepareHeaders(instance),
                body: params && JSON.stringify(params),
            });
        },
        onError: (error) => {
            console.error("Toggle battery notifications failed:", error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notification-contacts'] })
        },
    });
    return {
        mutate,
        isPending,
    };
}

export default useToggleBatteryNotifications;