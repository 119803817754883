import {
  Button,
  Checkbox,
  Divider,
  Group,
  Loader,
  Paper,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "react-responsive";
import { IconPlus, IconInfoCircle } from "@tabler/icons-react";
import { AddRecipiant } from "../AddRecipiant/AddRecipiant";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { formatDateTime } from "../../../utils";
import { ContactsList } from "../ContactsList/ContactsList.tsx";
import { SearchEmployee } from "../../SearchEmployee/SearchEmployee.tsx";
import { WorkZone } from "../../../models/Project.ts";
import useCreateNotificationContact from "../../../data/hooks/NotificationContact/useCreateNotificationContactHook.ts";
import useGetNotificationContactsInWorkZone from "../../../data/hooks/NotificationContact/useGetNotificationContactsInWorkZoneHook.ts";
import useToggleExternalConnection from "../../../data/hooks/Project/useToggleExternalConnectionHook.ts";
import useSnoozeProject from "../../../data/hooks/Project/useSnoozeProjectHook.ts";
import useUnSnoozeProject from "../../../data/hooks/Project/useUnSnoozeProjectHook.ts";
import { Employee } from "../../../models/Employee.ts";
import useGetMyUserInformation from "../../../data/hooks/User/useGetUserInformationHook.ts";
import { getEmployees } from "../../../services/auth.ts";
import { IdentityProvider } from "../../../models/enums/UserEnums.ts";

export const ProjectSettings = (props: { project: WorkZone }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const [showInput, setShowInput] = useState<boolean>(false);
  const { instance } = useMsal();

  const { mutate: createNotificationContact, isPending: createNotificationContactPending } = useCreateNotificationContact(instance);
  const { notificationContacts, isLoading: notificationContactsLoading } = useGetNotificationContactsInWorkZone({ instance, projectId: props.project.id });

  const contactsLoading = notificationContactsLoading || createNotificationContactPending;
  const [snoozeAlarms, setSnoozeAlarms] = useState<boolean>(false);

  const { mutate: toggleExternalConnection } = useToggleExternalConnection(instance);
  const { mutate: snoozeProject, isPending: snoozePending } = useSnoozeProject(instance);
  const { mutate: unsnoozeProject } = useUnSnoozeProject(instance);
  
  const { userInformation } = useGetMyUserInformation({ instance });

  useEffect(() => {
    setSnoozeAlarms(
      props.project.isSnoozed ? props.project.isSnoozed : false,
    );
  }, []);

  const fetchEmployees = async (
    query: string,
  ): Promise<Employee[]> => {
    return await getEmployees(instance, query);
  };

  const getDateOffset = (hours: string | null): Date => {
    const today = new Date();
    if (hours == null) {
      return today;
    }
    const offset = Number(hours.replace("h", ""));
    today.setHours(today.getHours() + offset);
    return today;
  };

  return (
    <>
      <Title order={3}>Alarms</Title>
      <Stack p={"md"}>
        <div>
          <Title order={4}>Snooze notifications</Title>
          <Text size="xs" c={"gray.6"}>
            Cancel all alarms for a set period of time
          </Text>
        </div>
        <Checkbox
          label="Cancel alarms"
          labelPosition="right"
          defaultChecked={props.project.isSnoozed}
          onChange={(event) => {
            setSnoozeAlarms(event.currentTarget.checked);
            if (props.project.isSnoozed) {
              unsnoozeProject({ id: props.project.id })
            }
          }}
        ></Checkbox>
        <Select
          disabled={!snoozeAlarms}
          placeholder="Select time"
          maw={isMobile ? "100%" : "300px"}
          data={["1h", "2h", "4h", "8h", "12h"]}
          onChange={(value) => {
            snoozeProject({ id: props.project.id, snoozedUntil: getDateOffset(value) })
          }}
        ></Select>
        {snoozePending ? <Loader ml={"md"} size={"sm"} type="dots" /> : null}

        {snoozeAlarms && props.project.snoozedUntil ? (
          <Text size="xs" c={"gray.6"}>
            {`Snoozed until ${formatDateTime(props.project.snoozedUntil)}`}
          </Text>
        ) : null}
        <Divider></Divider>
        <div>
          <Title order={4}>Alarm Notifications</Title>
          <Text size="xs" c={"gray.6"}>
            Set who should get notifications and when
          </Text>
        </div>
        <div>
          <Text size="sm">
            <strong>Internal users</strong>
          </Text>
        </div>
        <ContactsList
          contactsLoading={contactsLoading}
          instance={instance}
          contacts={notificationContacts}
          external={false}
        />
        {userInformation?.item.idp === IdentityProvider.RamuddenGlobalEntraId &&
            <SearchEmployee
                width={isMobile ? "100%" : "500px"}
                onChange={(values) => {
                  createNotificationContact({
                    workZoneId: props.project.id,
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    external: false,
                    timeZoneId: "Central European Time",
                    externalUserId: values.externalUserId,
                    timeFrame: { start: "00:00:00", end: "23:59:00", daysOfWeek: [0, 1, 2, 3, 4, 5, 6], },
                  })
                  setShowInput(false);
                }}
                onClose={() => {
                  setShowInput(false);
                }}
                fetchEmployees={fetchEmployees}
            />
        }
        <Divider size="md" />
        <div>
          <Text size="sm">
            <strong>Customer</strong>
          </Text>
        </div>
        <ContactsList
          contactsLoading={contactsLoading}
          instance={instance}
          contacts={notificationContacts}
          external={true}
        />
        {showInput ? (
          <AddRecipiant
            width={isMobile ? "100%" : "500px"}
            onSubmit={(values) => {
              createNotificationContact({
                workZoneId: props.project.id,
                name: values.name,
                email: values.email,
                phone: values.phone,
                external: true,
                timeZoneId: "Central European Time",
                timeFrame: { start: "00:00:00", end: "23:59:00", daysOfWeek: [0, 1, 2, 3, 4, 5, 6], },
              })
              setShowInput(false);
            }}
            onClose={() => {
              setShowInput(false);
            }}
          />
        ) : (
          <Button
            leftSection={<IconPlus size={"18px"} />}
            maw={isMobile ? "100%" : "fit-content"}
            variant="outline"
            onClick={() => {
              setShowInput(true);
            }}
          >
            Add contact
          </Button>
        )}
        <Divider size="md" />
        <div>
          <Title order={4}>Automatic work order</Title>
          <Text size="xs" c={"gray.6"}>
            Create work orders when alarms are registered
          </Text>
        </div>
        {props.project.externalId === null || props.project.workOrderSystem === null ? (
          <>
            <Checkbox
              label="Activate automatic work orders (External connection is missing)"
              labelPosition="right"
              disabled={true}
            />
            <Paper >
              <Group gap="sm" style={{
                border: '1px solid #228BE7',
                backgroundColor: '#228BE71A',
                borderRadius: '5px',
                padding: '8px',
                display: 'inline-flex',
                alignItems: 'center'
              }}>
                <IconInfoCircle size={"20px"} />
                <Text size={"sm"}>Only available if projects is connected to a work order system</Text>
              </Group>
            </Paper>
          </>
        ) : (
          <Checkbox
            label="Activate automatic work orders"
            labelPosition="right"
            py="md"
            defaultChecked={props.project.externalConnectionEnabled}
            onChange={() => {
              toggleExternalConnection({ id: props.project.id });
            }}
          />
        )}
      </Stack >
    </>
  );
};
