import { SimpleGrid } from "@mantine/core";

import { getSignsByCountry } from "../../../config/roadsigns";
import { Sign } from "../../routes/Pair";
import SignItem from "./components/SignItem";

type Props = {
  handleClick: (sign: Sign | null) => void;
  selected: Sign | null;
  searchString: string;
  countryCode: string;
};

export default function SignList({
  selected,
  handleClick,
  searchString,
  countryCode,
}: Props) {
  const countrySigns = getSignsByCountry(countryCode);
  const signs = Object.keys(countrySigns)
    .filter((sign) =>
      sign.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()),
    )
    .map((key) => {
      return (
        <SignItem
          active={selected?.value === countrySigns[key]}
          handleClick={handleClick}
          key={countrySigns[key]}
          name={key}
          value={countrySigns[key]}
        />
      );
    });

  return (
    <SimpleGrid pb={120} cols={4} style={{ overflow: "auto" }}>
      {signs}
    </SimpleGrid>
  );
}
