import { Stack, Title } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";

export const NoDevicesFound = () => {
  return (
    <Stack p={"lg"} w={"100%"} mt={"xl"} align="center">
      <IconSearch size={"3rem"} />
      <Title order={4} fw={300}>
        No devices found
      </Title>
    </Stack>
  );
};
