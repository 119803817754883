import { Button, Stack, FileInput, Loader, Center, Text } from "@mantine/core";
import { IconWand } from "@tabler/icons-react";
import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { getAttachmentByImage } from "../services/attachments";
import { getImageUrlForSign } from "../utils";
import { getSignsByCountry } from "../config/roadsigns.ts";

export const SignRecognition = (props: {
  selectedSign: string | null;
  setSelectedSign: (id: string) => void;
  countryCode: string;
}) => {
  const { instance } = useMsal();

  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [aiMessage, setAIMessage] = useState<string>("");
  const [roadSignUrl, setRoadSignUrl] = useState<string | null>(null);

  useEffect(() => {
    if (props.selectedSign) {
      const url = getImageUrlForSign(
        getSignsByCountry("SE")[props.selectedSign ?? ""],
      ); //TODO remove hardcode
      setRoadSignUrl(url);
    }
  }, [props.selectedSign]);

  const handleFileChange = (file: File | null) => {
    setFile(file);
  };

  const handleAiClick = async () => {
    if (file) {
      setAIMessage("");
      setLoading(true);
      setRoadSignUrl("");
      try {
        const attachment = await getAttachmentByImage(instance, file);
        props.setSelectedSign(attachment.code);
      } catch (error) {
        setAIMessage("AI failed to identify sign");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    props.countryCode == "SE" && (
      <Stack>
        <FileInput
          accept="image/png,image/jpeg"
          value={file}
          label="Use AI to identify sign"
          placeholder="Upload image"
          onChange={handleFileChange}
        />
        <Button disabled={!file} onClick={handleAiClick}>
          Identify
          <IconWand />
        </Button>
        <Center>
          {loading && <Loader />}
          {aiMessage && <Text mr="md">{aiMessage}</Text>}

          {roadSignUrl && (
            <img
              width={"100px"}
              src={roadSignUrl}
              alt="Road Sign"
              style={{ zIndex: 5, position: "relative" }}
            />
          )}
        </Center>
      </Stack>
    )
  );
};
