import { IPublicClientApplication } from "@azure/msal-browser";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { prepareHeaders } from "../../api/apiHelpers";

const baseUrl = import.meta.env.VITE_API_BASE_PATH;
const path = "/UserAccess/remove";

interface Params {
    userId: string;
    resource: string;
    scope: number;
}

const useRemoveUserAccess = (instance: IPublicClientApplication) => {
    const queryClient = useQueryClient();
    const {
        mutateAsync,
        mutate,
        isPending,
        isError,
    } = useMutation({
        mutationFn: async (params: Params) => {
            await fetch(`${baseUrl}${path}`, {
                mode: "cors",
                method: "POST",
                headers: await prepareHeaders(instance),
                body: JSON.stringify(params)
            })
        },
        onError: (error) => {
            console.error(`Error removing user access`, error);
        },
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['UserAccess', variables.userId] })
        },
    });

    return {
        mutateAsync,
        mutate,
        isPending,
        isError,
    }
}

export default useRemoveUserAccess;