import { Alert, Divider, Group, Modal, Stack, Text } from "@mantine/core";
import { useMobileDevice } from "../../../hooks/useMobileDevice.tsx";
import { IconAlertCircle } from "@tabler/icons-react";
import { useState } from "react";
import { EditAndCreateProjectInputForm } from "./EditAndCreateProjectInputForm.tsx";
import { useMsal } from "@azure/msal-react";
import useCloseProject from "../../../data/hooks/Project/useCloseProjectHook.ts";
import { PopoverConfirm } from "../../Popover/PopoverConfirm.tsx";
import { ConfirmButtonGroup } from "../../../shared/ConfirmButtonGroup/ConfirmButtonGroup.tsx";
import {
  CreateWorkZone,
  UpdateWorkZone,
  WorkZone,
} from "../../../models/Project.ts";
import useDepots from "../../../data/hooks/Depots/useDepots.tsx";

interface Props {
  isOpen: boolean;
  project: WorkZone;
  close: () => void;
  onSubmit: (project: UpdateWorkZone) => void;
}

export const EditProjectInformationModal = ({
  isOpen,
  project,
  close,
  onSubmit,
}: Props) => {
  const isMobile = useMobileDevice();
  const { instance } = useMsal();
  const { depots } = useDepots(instance);
  const [error, setError] = useState<boolean>(false);
  const [createForm, setCreateForm] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);
  const { mutateAsync: closeProject } = useCloseProject(instance);

  const handleEditProject = async (data: CreateWorkZone) => {
    try {
      onSubmit({
        id: project.id,
        name: data.name,
        customerName: data.customerName ?? null,
        startDate: data.startDate ?? null,
        endDate: data.endDate ?? null,
        isDemoProject: data.isDemoProject,
        depotId: data.depotId,
      });
    } catch (reason) {
      console.log("error", reason);
      setError(true);
    }
    if (error != null) {
      setCreateForm(false);
    }
  };

  const handleCloseProject = () => {
    setOpened(true);

    closeProject({ id: project.id }).then(() => {
      setTimeout(() => {
        setOpened(false);
        close();
      }, 500);
    });
  };

  return (
    <Modal.Root
      opened={isOpen}
      onClose={close}
      fullScreen={isMobile}
      size="350px"
      centered
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fw={600}>Edit project information</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Group wrap={isMobile ? "wrap" : "nowrap"} align="top">
            <Stack miw={"100%"}>
              <EditAndCreateProjectInputForm
                create={createForm}
                initialValues={project}
                depots={depots}
                onSubmit={(data) => {
                  if (data == null) {
                    setCreateForm(false);
                    return;
                  }
                  handleEditProject(data);
                }}
              />
              {error && (
                <Alert
                  variant="light"
                  color="red"
                  title="No location specified"
                  icon={<IconAlertCircle />}
                >
                  Error in form
                </Alert>
              )}
            </Stack>
          </Group>
          <Divider mt={"md"} />

          <Group p="center">
            <PopoverConfirm
              text="Are you sure you want to close the project?"
              btnText="Close Project"
              opened={opened}
              setOpened={(value) => setOpened(value)}
              width={300}
              onClick={handleCloseProject}
              position={isMobile ? "bottom-start" : "right-start"}
            />
          </Group>
          <Text size={"xs"} mt={"sm"} c={"gray"}>
            Note! When closing a project, devices connected to the project get
            unassigned. This action cannot be undone.
          </Text>
          <Divider my={"md"} />
          <ConfirmButtonGroup
            confirmBtnText="Save"
            disabled={opened}
            onAbort={close}
            onConfirm={() => setCreateForm(true)}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
