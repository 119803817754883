import { IPublicClientApplication } from "@azure/msal-browser";
import {
    useInfiniteQuery,
    InfiniteData,
    QueryKey,
} from "@tanstack/react-query";
import { useState } from "react";
import { defaultProjectFilters } from "../../filters";
import { WorkZone } from "../../../models/Project";
import { useDebouncedCallback, useSessionStorage } from "@mantine/hooks";
import useInfiniteScroll from "../useInfiniteScroll";
import { APIResponseList, buildQueryString, prepareHeaders } from "../../api/apiHelpers";

const path = `${import.meta.env.VITE_API_BASE_PATH}/workZone/list`;

const useInfProjectList = (instance: IPublicClientApplication, pageSize: number = 20, includeDemoProjectsInitValue = false) => {
    const [search, setSearch] = useState("");
    const [includeDemoProjects, setIncludeDemoProjects] = useState(includeDemoProjectsInitValue);
    const [filters, setFilters] = useState(defaultProjectFilters);
    const [depot, setDepot] = useSessionStorage<string[]>({
        key: 'depots',
        defaultValue: []
    });

    const {
        data,
        isLoading,
        isError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery<APIResponseList<WorkZone>, Error, InfiniteData<APIResponseList<WorkZone>>, QueryKey, number>({
        queryKey: ["projects", pageSize, includeDemoProjects, depot, { filters }],
        queryFn: async ({ pageParam }) => {
            const queryString = buildQueryString({ ...filters, includeDemoProjects, depot, pageSize, pageNumber: pageParam });
            const response = await fetch(`${path}${queryString}`, {
                mode: "cors",
                method: "GET",
                headers: await prepareHeaders(instance),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return {
                items: data.data,
                pageSize: data.pageSize,
                pageNumber: data.pageNumber,
                totalCount: data.totalCount,
            };
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
            const nextPage = lastPage.pageNumber < lastPage.totalCount ? lastPage.pageNumber + 1 : undefined;
            return nextPage;
        },
    });

    useInfiniteScroll(fetchNextPage, isLoading, isFetchingNextPage, hasNextPage);

    const handleSearchChange = (searchString: string) => {
        setSearch(searchString);
        handleSearch(searchString);
    };

    const handleSearch = useDebouncedCallback(async (search: string) => {
        setFilters({ searchTerm: search });
    }, 500);

    const projects = data?.pages.flatMap((page) => page.items) ?? [];

    return {
        projects,
        isLoading,
        isError,
        search,
        handleSearchChange,
        fetchNextPage,
        setFilters,
        setSearch,
        hasNextPage,
        isFetchingNextPage,
        includeDemoProjects,
        setIncludeDemoProjects,
        depot,
        setDepot,
    };
};

export default useInfProjectList;
