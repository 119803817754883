import { useEffect, useState } from "react";
import {
  IconMap,
  IconTrafficLights,
  IconLogout,
  IconX,
  IconBuildingWarehouse,
  IconLock,
  IconLayoutSidebarLeftCollapseFilled,
  IconLayoutSidebarLeftExpandFilled,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import {
  Button,
  Divider,
  Group,
  Menu,
  Modal,
  Space,
  Stack,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { UserButton } from "./UserButton";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import BugReport from "./BugReport";
import { useDisclosure } from "@mantine/hooks";
import { IconBug } from "@tabler/icons-react";
import useMyUserAccesses from "../../data/hooks/UserAccess/useMyUserAccess";
import { useMobileDevice } from "../../hooks/useMobileDevice";
import { FlexSpacer } from "../../shared/FlexSpacer";
import { useAuthUtils } from "../../shared/authUtils.tsx";

interface Props {
  toggleNavbar: () => void;
  toggleMinimize: () => void;
}

const linkData = [
  { link: "/projects", label: "Home", icon: IconBuildingWarehouse },
  { link: "/map", label: "Map", icon: IconMap },
  { link: "/devices", label: "Devices", icon: IconTrafficLights },
];

function getActiveMatch(active: string, link: string) {
  if (active === "/" || link === "/") {
    return link === active;
  } else {
    return active.startsWith(link);
  }
}

export function NavbarSegmented({ toggleNavbar, toggleMinimize }: Props) {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const { instance } = useMsal();
  const { userAccesses } = useMyUserAccesses({ instance: instance });
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMobileDevice();
  const [minimize, setMinimize] = useState(false);

  useEffect(() => {
    setActive(location.pathname);
  }, [location]);

  const { handleLogout } = useAuthUtils();

  const handleLinkClick = (link: string) => {
    toggleNavbar();
    setActive(link);
  };

  const links = linkData.map(({ link, label, icon: Icon }) => (
    <Link
      to={link}
      className={"link"}
      key={link}
      data-active={getActiveMatch(active, link) || undefined}
      onClick={() => handleLinkClick(link)}
    >
      <Icon size={"24px"} color="gray"></Icon>
      {minimize ? null : <span style={{ marginLeft: "8px" }}>{label}</span>}
    </Link>
  ));

  return (
    <Stack className="links" p={"md"}>
      <Tooltip label={minimize ? "Expand" : "Collapse"} position="right">
        <UnstyledButton
          bd={"4px solid transparent"}
          ta={minimize ? "left" : "right"}
          pl={"sm"}
          c={"gray"}
          onClick={() => (setMinimize(!minimize), toggleMinimize())}
        >
          {minimize ? (
            <IconLayoutSidebarLeftExpandFilled size={24} />
          ) : (
            <IconLayoutSidebarLeftCollapseFilled size={24} />
          )}
        </UnstyledButton>
      </Tooltip>
      <Divider mb="md" color="lightgrey" />
      {links}
      <FlexSpacer />
      <Stack gap={"xs"}>
        <Modal.Root opened={opened} onClose={close} centered>
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Header>
              <Modal.Title fw={600}>Bug Report</Modal.Title>
              <UnstyledButton onClick={close}>
                <IconX />
              </UnstyledButton>
            </Modal.Header>
            <Modal.Body>
              <BugReport onSubmit={close} />
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
        <Button w={"100%"} size="xs" onClick={open} variant="default">
          <IconBug size={16} />
          <Space w={minimize ? 0 : "xs"} />
          {minimize ? null : "Report Bug"}
        </Button>
        <Divider mb="md" mt={"md"} color="lightgrey" />
        <Menu position={isMobile ? "top-end" : "right-end"} offset={20}>
          <Menu.Target>
            <div>
              <UserButton minimized={minimize} />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            {userAccesses?.isAdmin && (
              <Menu.Item p="sm" component={Link} to="/access-management">
                <Group>
                  <IconLock size={14} />
                  Access Management
                </Group>
              </Menu.Item>
            )}
            <Menu.Item p="sm" onClick={handleLogout}>
              <Group>
                <IconLogout size={14} />
                Logout
              </Group>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Stack>
    </Stack>
  );
}
