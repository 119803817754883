import { Group, ScrollArea, Stack, Title } from "@mantine/core";
import { useMsal } from "@azure/msal-react";
import { AddDeviceForm, AddDeviceInput } from "./AddDeviceInput";
import useAssignDeviceToProject from "../../../data/hooks/Device/useAssignDeviceToProjectHook";
import { WorkZone } from "../../../models/Project";
import useProjectDevices from "../../../data/hooks/Device/useProjectDevicesHook";
import { ConnectedDevices } from "./ConnectedDevices";

interface Props {
  project: WorkZone;
}

export const ManageDevices = ({ project }: Props) => {
  const { instance } = useMsal();
  const { mutateAsync: removeDeviceFromProject } =
    useAssignDeviceToProject(instance);

  const { projectDevices } = useProjectDevices({
    instance,
    projectIds: [project.id],
  });

  const { mutateAsync: assignDeviceToProject } =
    useAssignDeviceToProject(instance);

  const addDevice = (data: AddDeviceForm | null) => {
    if (data && data.projectId && data.deviceId) {
      assignDeviceToProject({
        workZoneId: data.projectId,
        deviceId: data.deviceId,
        equipmentType: data.equipmentType,
        currentName: data.currentName,
        attachmentRef: data.attachmentRef,
      });
    }
  };

  const handleRemoveDevice = (deviceId: string) => {
    removeDeviceFromProject({
      workZoneId: null,
      deviceId: deviceId,
      equipmentType: null,
      currentName: null,
      attachmentRef: null,
    });
  };

  return (
    <>
      <Title order={3} ta={"left"}>
        Manage Devices
      </Title>
      <Group grow gap={"xl"} align="top">
        <Stack>
          <AddDeviceInput
            project={project}
            onSubmit={(data) => addDevice(data)}
          ></AddDeviceInput>
        </Stack>
        <ScrollArea maw={"100%"} h={"60vh"}>
          <ConnectedDevices
            devices={projectDevices[project.id]}
            onRemove={(id) => handleRemoveDevice(id)}
          />
        </ScrollArea>
      </Group>
    </>
  );
};
