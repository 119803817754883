import { Stack, Text, Title } from "@mantine/core";
import { BatteryStatus } from "../../models/enums/DeviceEnums";
import { BatteryIcon } from "../components/BatteryIcon";

type Props = {
  type: BatteryStatus.CRITICAL | BatteryStatus.OUT_OF_BATTERY;
};

export default function BatteryBlockerScreen({ type }: Props) {
  return (
    <Stack
      flex={1}
      align="center"
      justify="center"
      bg={type == BatteryStatus.CRITICAL ? "#fa5252" : "#dddddd"}
      mb="lg"
      style={{ borderRadius: "24px" }}
    >
      <BatteryIcon batteryStatus={type} size={150} stroke={1.2} />

      <Title order={1} size="h1">
        {type == BatteryStatus.CRITICAL ? "Critical battery" : "Out of battery"}
      </Title>

      <Text size="xl">Select a different device</Text>
    </Stack>
  );
}
