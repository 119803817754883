import { useNavigate } from "react-router-dom";
import { Device } from "../../../models/Device";
import { WorkZone } from "../../../models/Project";
import { panTo } from "../../../utils";
import { DeviceInfoCardComponent } from "./DeviceInfoCard";
import { WorkzoneInfoCardComponent } from "./WorkzoneInfoCard";

type Props = {
  project: WorkZone | null;
  device: Device | null;
  projectDevices: Device[];
  projectName: string;
  depotName: string;
  onClose: () => void;
};

export const InfoCardWrapper = ({
  project,
  device,
  onClose,
  projectDevices,
  projectName,
  depotName,
}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      {project && (
        <WorkzoneInfoCardComponent
          workZone={project}
          devices={projectDevices}
          depotName={depotName}
          onClose={onClose}
          panTo={panTo}
          onNavigateToProject={(projectId: string) =>
            navigate(`/projects/${projectId}`)
          }
        />
      )}
      {device && (
        <DeviceInfoCardComponent
          device={device}
          projectName={projectName}
          onClose={onClose}
          onNavigateToProjectWithSelectedDevice={(
            projectId: string,
            deviceId: string,
          ) => navigate(`/projects/${projectId}?ref=${deviceId}`)}
        />
      )}
    </>
  );
};
