import { Group, Text } from "@mantine/core";
import { IconInfoTriangle } from "@tabler/icons-react";

import styles from "./styles.module.css";

export default function DeviceActivationInfo() {
  return (
    <Group px={20} py={10} bg="#ffae0033" className={styles.container}>
      <IconInfoTriangle size={50} stroke={1.25} />
      <Text flex={1} fw={600}>
        Remember to activate the device by a soft hit or shake
      </Text>
    </Group>
  );
}
