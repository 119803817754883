import { IconBarrierBlock, IconRoadSign } from "@tabler/icons-react";
import { Device } from "../models/Device";
import { getImageUrlForSign } from "../utils";
import { EquipmentType } from "../models/enums/DeviceEnums";

type Props = {
  device: Device;
};

export const DeviceImage = ({ device }: Props) => {
  const getRoadSign = () => {
    const roadSignUrl = getImageUrlForSign(device.attachmentRef);
    return roadSignUrl ? (
      <div style={{ marginTop: "6px" }}>
        <img width={"40px"} src={roadSignUrl} alt="" />
      </div>
    ) : (
      <IconRoadSign stroke={1.5} color="gray" size={40} />
    );
  };

  const image =
    device.currentEquipmentType == EquipmentType.Barrier ? (
      <IconBarrierBlock stroke={1.5} color="gray" size={40} />
    ) : (
      getRoadSign()
    );
  return image;
};
