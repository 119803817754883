import { Container, Flex } from "@mantine/core";
import styles from "../routes/styles.module.css";
import { ReactNode, useContext } from "react";
import { PWAContext } from "../providers/PWAProvider";
import Toast from "./Toast";
import NavigationFooter from "./NavigationFooter/NavigationFooter";
import AppHeader from "./AppHeader/AppHeader";

export default function PwaWrapper({ children }: { children: ReactNode }) {
  const { message } = useContext(PWAContext);

  return (
    <Container
      className={styles.pwaRoot}
      h="100vh"
      w="100vw"
      display="flex"
      style={{ flexDirection: "column" }}
    >
      {message && <Toast message={message} />}
      <AppHeader />
      <Flex direction="column" px={20} flex={1} style={{ overflow: "auto" }}>
        {children}
      </Flex>
      <NavigationFooter />
    </Container>
  );
}
