import { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  CloseButton,
  Flex,
  Group,
  InputBase,
  Loader,
  Pill,
  Stack,
  Text,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { IconCurrentLocation, IconSearch } from "@tabler/icons-react";
import { getProjectsByClosest } from "../../services/workZones";
import { PWAContext } from "../providers/PWAProvider";
import { getLocation } from "../utils/location";
import ProjectList from "../components/Project/List/ProjectList";
import { WorkZone } from "../../models/Project";
import useInfProjectList from "../../data/hooks/Project/useInfProjectListHook";

export default function Projects() {
  const { instance } = useMsal();
  const { setProject, setMessage, project } = useContext(PWAContext);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const [coordinates, setCoordinates] = useState<{
    latitude: number | null;
    longitude: number | null;
  }>({ latitude: null, longitude: null });

  const [loading, setLoading] = useState(false);
  const [filterByClosest, setFilterByClosest] = useState(false);
  const [projectsHere, setProjects] = useState<WorkZone[]>([]);

  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    projects,
    isLoading: projectsLoading,
    search,
    handleSearchChange,
    includeDemoProjects,
    setIncludeDemoProjects,
  } = useInfProjectList(instance);

  const filterOnClosest = () => {
    setLoading(true);
    setFilterByClosest(true);

    timeout.current = setTimeout(() => {
      if (!coordinates.latitude || !coordinates.longitude) {
        setMessage({
          title: "Fetching closest project is taking some time",
          text: "Make sure that your permissions are set to Allow. To get more information on how to do this, see the Help section in the top right corner",
          type: "info",
        });
      }
    }, 10000);

    getLocation(setCoordinates, setMessage, setLoading);
  };

  const fetchProjects = () => {
    setLoading(true);
    if (coordinates.latitude && coordinates.longitude)
      getProjectsByClosest(instance, [
        coordinates.longitude,
        coordinates.latitude,
      ])
        .then((data) => setProjects(data))
        .catch(() => {
          setMessage({
            title: "Failed to fetch projects",
            text: "Something when wrong when fetching the projects closest to you",
            type: "error",
          });
          setFilterByClosest(false);
        })
        .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (coordinates.latitude && coordinates.longitude) {
      fetchProjects();
    }
    clearTimeout(timeout.current as NodeJS.Timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates]);

  const selectProject = (newProject?: WorkZone) => {
    if (!newProject) return;
    setProject(newProject);
    clearTimeout(timeout.current as NodeJS.Timeout);
    navigate(`/pwa/projects/${newProject.id}`);
  };

  return (
    <>
      <Stack>
        <Checkbox
          label="Show demo projects"
          labelPosition="right"
          defaultChecked={includeDemoProjects}
          checked={includeDemoProjects}
          onChange={() => {
            setIncludeDemoProjects(!includeDemoProjects);
          }}
        />

        <Group>
          <InputBase
            leftSection={<IconSearch />}
            size="md"
            rightSection={
              search !== "" && (
                <CloseButton
                  size="sm"
                  onMouseDown={(event) => event.preventDefault()}
                  onClick={() => handleSearchChange("")}
                  aria-label="Clear value"
                />
              )
            }
            value={search}
            onChange={(e) => handleSearchChange(e.currentTarget.value)}
            flex={1}
          />
          <Button
            onClick={() => filterOnClosest()}
            variant={filterByClosest ? "filled" : "light"}
            size="md"
          >
            <IconCurrentLocation />
          </Button>
        </Group>

        <Group>
          <Text size="sm" c="var(--mantine-color-deepGreen-4)">
            Filter by:
          </Text>
          {filterByClosest && (
            <Pill
              onRemove={() => {
                setFilterByClosest(false);
                setLoading(false);
              }}
              withRemoveButton
            >
              closest
            </Pill>
          )}
          {searchString && (
            <Pill
              onRemove={() => {
                setSearchString("");
                setLoading(false);
              }}
              withRemoveButton
            >
              {searchString}
            </Pill>
          )}
        </Group>
      </Stack>

      {projectsLoading || loading ? (
        <Flex flex={1} h={200} align="center" justify="center">
          <Loader type="dots" color="var(--mantine-color-deepGreen-7)" />
        </Flex>
      ) : (
        <Flex direction="column" flex={1} style={{ overflow: "auto" }}>
          <ProjectList
            onSelect={selectProject}
            searchString={searchString}
            projects={filterByClosest ? projectsHere : projects}
            projectName={project?.name}
          />
        </Flex>
      )}
    </>
  );
}
