import { buildQueryString, prepareHeaders } from "./apiHelpers";
import { IPublicClientApplication } from "@azure/msal-browser";
import { SupervisionReportDto } from "../../models/SupervisionReport.ts";
import { UserInformationDto } from "../../models/User.ts";

const baseUrl = import.meta.env.VITE_API_BASE_PATH;

export interface APIResponseSingleItem<T> {
  item: T;
}

type Model = SupervisionReportDto | UserInformationDto;

const fetchDataSingleItem = async <T extends Model>(
  path: string,
  params: { [key: string]: any },
  instance: IPublicClientApplication,
): Promise<APIResponseSingleItem<T>> => {
  const queryString = buildQueryString(params);
  const response = await fetch(`${baseUrl}${path}${queryString}`, {
    mode: "cors",
    method: "GET",
    headers: await prepareHeaders(instance),
  });
  if (!response.ok) {
    throw await response.json();
  }
  const data = await response.json();
  return {
    item: data ?? null,
  };
};

export default fetchDataSingleItem;
