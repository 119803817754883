import { Button } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const FallbackComponent: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div style={styles.container}>
            <h1>Something went wrong.</h1>
            <Button onClick={() => navigate('/home')}>Go to home page</Button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center' as const,
        padding: '1rem',
    },
};

export default FallbackComponent;