import { useEffect, useState } from "react";
import { Combobox, InputBase, useCombobox, CloseButton } from "@mantine/core";

export function SearchableSelect(props: {
  width?: string;
  placeholder?: string;
  label?: string;
  value?: { label: string; id: string };
  items: { label: string; id: string }[];
  emitter: (zone: { label: string; id: string } | null) => void;
  setFilters?: (filters: any) => void;
  disabled?: boolean;
}) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  
  const [value, setValue] = useState<string | null>(null);
  const [search, setSearch] = useState("");
  
  useEffect(() => {
    if (props.value?.label) {
      setValue(props.value.label);
      setSearch(props.value.label);
    }
  }, [props?.value?.label]);
  
  const handleSearchChange = (search: string) => {
    setSearch(search);
    if (props.setFilters) {
      props.setFilters({ searchTerm: search });
    }
  }
  
  const options = props.items.map((item) => (
    <Combobox.Option value={item.label} key={item.id}>
      {item.label}
    </Combobox.Option>
  ));
  
  return (
    <div style={{ width: props.width ? props.width : "100%" }}>
      <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={(val) => {
          if (!props.disabled) {
            setValue(val);
            setSearch(val);
            props.emitter(props.items.filter((item) => item.label === val)[0]);
            combobox.closeDropdown();
          }
        }}
      >
        <Combobox.Target>
          <InputBase
            label={props.label}
            ta={"left"}
            rightSection={
              !props.disabled && value !== null ? (
                <CloseButton
                  size="sm"
                  onMouseDown={(event) => event.preventDefault()}
                  onClick={() => {
                    setValue(null);
                    handleSearchChange("");
                    props.emitter(null);
                  }}
                  aria-label="Clear value"
                />
              ) : (
                <Combobox.Chevron />
              )
            }
            value={search}
            onChange={(event) => {
              if (!props.disabled) {
                combobox.openDropdown();
                combobox.updateSelectedOptionIndex();
                handleSearchChange(event.currentTarget.value);
              }
            }}
            onClick={() => !props.disabled && combobox.openDropdown()}
            onFocus={() => !props.disabled && combobox.openDropdown()}
            onBlur={() => {
              combobox.closeDropdown();
              handleSearchChange(value || "");
            }}
            placeholder={props.placeholder || ""}
            rightSectionPointerEvents={value === null ? "none" : "all"}
            disabled={props.disabled}
          ></InputBase>
        </Combobox.Target>
        
        <Combobox.Dropdown>
          <Combobox.Options ta={"left"} mah={250} style={{ overflowY: "auto" }}>
            {options.length > 0 ? (
              options
            ) : (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  );
}
