import { useQueryClient, useMutation } from "@tanstack/react-query";
import { TimeFrame } from "../../../models/NotificationContact";
import { IPublicClientApplication } from "@azure/msal-browser";
import { prepareHeaders } from "../../api/apiHelpers";

interface Params {
    timeFrame: TimeFrame;
    NotificationContactId: string;
}

const path = `${import.meta.env.VITE_API_BASE_PATH}/notificationContact/add-time-frame`;

const useAddNotificationContactTimeFrame = (instance: IPublicClientApplication) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        mutateAsync,
        isPending
    } = useMutation({
        mutationFn: async (params: Params) => {
            return await fetch(path, {
                mode: 'cors',
                method: 'POST',
                headers: await prepareHeaders(instance),
                body: params && JSON.stringify(params),
            });
        },
        onError: (error) => {
            console.error("Add notification contact time frame failed:", error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notification-contacts'] })
        },
    });

    return {
        mutate,
        mutateAsync,
        isPending,
    }
}

export default useAddNotificationContactTimeFrame;