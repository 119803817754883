import { Group } from "@mantine/core";
import NavItem from "./NavItem";
import {
  IconBarrierBlock,
  IconHelp,
  IconStethoscope,
} from "@tabler/icons-react";

export default function NavigationFooter() {
  return (
    <Group w="100%" flex={0}>
      <NavItem href="/pwa/projects" icon={<IconBarrierBlock />}>
        Projects
      </NavItem>
      <NavItem href="/pwa/health-check" icon={<IconStethoscope />}>
        Health Check
      </NavItem>
      <NavItem href="/pwa/help" icon={<IconHelp />}>
        Help
      </NavItem>
    </Group>
  );
}
