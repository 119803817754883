import { IPublicClientApplication } from "@azure/msal-browser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import postData from "../../api/postData";
import { UpdateWorkZone } from "../../../models/Project";

const path = `/workzone/set-information`;

const useEditProject = (instance: IPublicClientApplication) => {
    const queryClient = useQueryClient();
    const {
        mutateAsync,
        mutate,
        isPending,
        isError,
        isSuccess,
    } = useMutation({
        mutationFn: (params: UpdateWorkZone) => { return postData<UpdateWorkZone>(path, params, instance) },
        onError: (error) => {
            console.error(`Error assigning device to project`, error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['project'] })
        },
    });

    return {
        mutateAsync,
        mutate,
        isPending,
        isError,
        isSuccess,
    }
}

export default useEditProject;