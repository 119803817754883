import SupervisionReportTable from "./SupervisionReport";
import { Loader, Stack } from "@mantine/core";
import { useState } from "react";
import { DatePickerInput } from "@mantine/dates";
import { useMsal } from "@azure/msal-react";
import { IconAlertTriangle } from "@tabler/icons-react";
import { getEndOfDay } from "../../../utils";
import { WorkZone } from "../../../models/Project";
import useGetSupervisionReport from "../../../data/hooks/SupervisionReport/useGetSupervisionReportHook.ts";
import { supervisionReportIntervals } from "../../../config/supervisionReportIntervals.ts";

const DEFAULT_NUMBER_OF_DAYS = 7;

export default function Reports(props: { workzone: WorkZone }) {
  const { instance } = useMsal();
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - DEFAULT_NUMBER_OF_DAYS);
  sevenDaysAgo.setHours(0, 0, 0, 0);
  today.setHours(23, 59, 59, 999);
  const intervalInHours =
    supervisionReportIntervals[props.workzone.countryCode] ?? "08:00:00";

  const { startDate, endDate } = getInitialReportDates(props.workzone);
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([
    startDate,
    endDate,
  ]);

  const { supervisionReport, isLoading: supervisionReportsLoading } =
    useGetSupervisionReport({
      instance: instance,
      workZoneId: props.workzone.id,
      startTime:
        dateValue[0] && dateValue[1]
          ? dateValue[0].toISOString()
          : sevenDaysAgo.toISOString(),
      endTime:
        dateValue[0] && dateValue[1]
          ? getEndOfDay(dateValue[1]).toISOString()
          : today.toISOString(),
      interval: intervalInHours,
    });

  return (
    <Stack gap={"xs"} py={"md"}>
      <DatePickerInput
        maw={"300px"}
        type="range"
        label="Select date range"
        placeholder="Pick dates range"
        value={dateValue}
        onChange={(val) => setDateValue(val)}
        maxDate={new Date()}
        allowSingleDateInRange
      />
      {supervisionReportsLoading ? (
        <Loader mt={"xl"} mx={"auto"}></Loader>
      ) : (
        <>
          {" "}
          {supervisionReport?.rows.length ? (
            <SupervisionReportTable
              intervalInHours={
                intervalInHours ? Number.parseInt(intervalInHours) : null
              }
              data={supervisionReport}
              exportStartDate={dateValue[0] || startDate}
              exportEndDate={dateValue[1] || endDate}
              projectId={props.workzone.id}
              projectName={props.workzone.name}
            />
          ) : (
            <div
              style={{ textAlign: "center", padding: "20px", color: "#666" }}
            >
              <IconAlertTriangle size={48} style={{ color: "#FFC107" }} />
              <h3>No data available for the selected period</h3>
              <p>
                Please try selecting a different date range or check if the data
                for this project is available during the selected period.
              </p>
            </div>
          )}
        </>
      )}
    </Stack>
  );
}

const getInitialReportDates = (
  workZone: WorkZone,
): { startDate: Date; endDate: Date } => {
  const defaultDays = DEFAULT_NUMBER_OF_DAYS;

  // Set endDate based on whether the project is closed
  const endDate =
    workZone.isClosed && workZone.closedAt
      ? new Date(workZone.closedAt)
      : new Date();

  // Set startDate relative to endDate
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - defaultDays);
  startDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, milliseconds

  return { startDate, endDate };
};
