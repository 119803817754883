import { BatteryStatus } from "../../models/enums/DeviceEnums";
import {
  IconBattery,
  IconBattery1,
  IconBattery2,
  IconBattery4,
} from "@tabler/icons-react";

interface Props {
  batteryStatus: BatteryStatus;
  size?: number;
  color?: string;
  stroke?: number;
}

export const BatteryIcon = ({
  batteryStatus,
  size = 24,
  color = "black",
  stroke,
}: Props) => {
  if (batteryStatus === BatteryStatus.CRITICAL)
    return <IconBattery1 size={size} color={color} stroke={stroke} />;
  if (batteryStatus === BatteryStatus.LOW)
    return <IconBattery2 size={size} color={color} stroke={stroke} />;
  if (batteryStatus === BatteryStatus.OUT_OF_BATTERY)
    return <IconBattery size={size} color={color} stroke={stroke} />;
  return <IconBattery4 size={size} color={color} stroke={stroke} />;
};
