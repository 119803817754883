
import { Device } from '../../models/Device';
import { DeviceLog } from '../../models/DeviceLog';
import { WorkZone } from '../../models/Project';
import { prepareHeaders } from './apiHelpers';
import { IPublicClientApplication } from "@azure/msal-browser";
const baseUrl = import.meta.env.VITE_API_BASE_PATH;

export interface APIResponsePoint<T> {
    item: T;
}

type Model = Device | DeviceLog | WorkZone;

const fetchDataById = async <T extends Model>(
    path: string,
    id: string,
    instance: IPublicClientApplication
): Promise<APIResponsePoint<T>> => {
    const response = await fetch(`${baseUrl}${path}/${id}`, {
        mode: 'cors',
        method: 'GET',
        headers: await prepareHeaders(instance)
    });
    if (!response.ok) {
        throw await response.json();
    }
    const data = await response.json();
    return {
        item: data
    };
}

export default fetchDataById;