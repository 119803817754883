import { useQueries, UseQueryResult } from '@tanstack/react-query';
import fetchDataList, { APIResponseList } from '../../api/fetchDataList';
import QueryKeys from '../../queryKeys';
import { DeviceFilters } from '../../filters';
import { IPublicClientApplication } from "@azure/msal-browser";
import { Device } from '../../../models/Device';
import { useCallback } from 'react';

interface Params {
    instance: IPublicClientApplication;
    projectIds: string[];
}

const path = `/device/paginated`;

const useProjectDevices = ({ instance, projectIds }: Params) => {
    const pageNumber = 1;
    const pageSize = 1000;

    const projectDevicesData = useQueries({
        queries: projectIds.map((projectId) => ({
            queryKey: QueryKeys.Devices.list(pageNumber, pageSize, { workZoneId: projectId } as DeviceFilters),
            queryFn: () => fetchDataList(path, { pageNumber, pageSize, ...{ workZoneId: projectId } }, instance),
            enabled: !!projectId,
            refetchInterval: 60000,
        }))
    })  as UseQueryResult<APIResponseList<Device>, Error>[];

    
    const createWorkZoneDeviceMap = useCallback((devices: Device[], projectIds: string[]): { [projectId: string]: Device[] } => {
        return projectIds.reduce((map, id) => {
          map[id] = devices.filter((d) => d.workZoneId === id);
          return map;
        }, {} as { [projectId: string]: Device[] });
      }, []);
    
    const isLoading = projectDevicesData.some((query) => query.isLoading)
    const deviceList = projectDevicesData.map(query => query.data?.items || [])
    const projectDevices = createWorkZoneDeviceMap(deviceList.flat(), projectIds);
    return {
        projectDevices,
        isLoading
    }
};

export default useProjectDevices;