import { Box, Divider, Skeleton, Text } from "@mantine/core";
import { IPublicClientApplication } from "@azure/msal-browser";
import { ProjectTimeFrame } from "../ProjectTimeFrame/ProjectTimeFrame.tsx";
import { ProjectContact } from "../ProjectContact/ProjectContact.tsx";
import { NotificationContact, TimeFrame } from "../../../models/NotificationContact.ts";
import useUpdateNotificationContactTimeFrame from "../../../data/hooks/NotificationContact/useUpdateNotificationContactTimeFrameHook.ts";
import useAddNotificationContactTimeFrame from "../../../data/hooks/NotificationContact/useAddNotificationContactTimeFrameHook.ts";
import useDeleteNotificationContact from "../../../data/hooks/NotificationContact/useDeleteNotificationContactHook.ts";
import useRemoveNotificationContactTimeFrame from "../../../data/hooks/NotificationContact/useDeleteNotificationContactTimeFrameHook.ts";
import useToggleBatteryNotifications from "../../../data/hooks/NotificationContact/useToggleBatteryNotifications.ts";
import useToggleEmailNotifications from "../../../data/hooks/NotificationContact/useToggleEmailNotifications.ts";
import useToggleSmsNotifications from "../../../data/hooks/NotificationContact/useToggleSmsNotifications.ts";

export const ContactsList = (props: {
  contactsLoading: boolean;
  contacts: NotificationContact[] | undefined;
  instance: IPublicClientApplication;
  external: boolean;
}) => {
  const {
    contactsLoading,
    contacts,
    instance,
    external,
  } = props;

  const { mutate: addNotificationContactTimeFrame, isPending: addNotificationContactTimeFramePending } = useAddNotificationContactTimeFrame(instance);
  const { mutate: updateNotificationContactTimeFrame, isPending: updateNotificationContactTimeFramePending } = useUpdateNotificationContactTimeFrame(instance);
  const { mutate: deleteNotificationContact, isPending: deleteNotificationContactPending } = useDeleteNotificationContact(instance);
  const { mutate: deleteNotificationContactTimeFrame, isPending: deleteNotificationContactTimeFramePending } = useRemoveNotificationContactTimeFrame(instance);

  const { mutate: toggleBatteryAlarmNotification, isPending: toggleBatteryAlarmNotificationPending } = useToggleBatteryNotifications(instance);
  const { mutate: toggleEmailNotification, isPending: toggleEmailNotificationPending } = useToggleEmailNotifications(instance);
  const { mutate: toggleSmsNotification, isPending: toggleSmsNotificationPending } = useToggleSmsNotifications(instance);



  const loading = toggleBatteryAlarmNotificationPending || toggleEmailNotificationPending || toggleSmsNotificationPending || addNotificationContactTimeFramePending
    || deleteNotificationContactPending || deleteNotificationContactTimeFramePending
    || updateNotificationContactTimeFramePending || contactsLoading;

  return (
    <>
      {loading ? (
        <>
          {[0, 1].map(index => (
            <Box key={index} p={"xl"} w={"70%"}>
              <Skeleton height={50} circle mb="xl" />
              <Skeleton height={8} radius="xl" />
              <Skeleton height={8} mt={6} radius="xl" />
              <Skeleton height={8} mt={6} width="70%" radius="xl" />
            </Box>
          ))}
        </>
      ) : contacts && contacts.length > 0 ? (
        <>
          {contacts
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((contact) => contact.external === external)
            .map((contact, idx) => (
              <div key={idx}>
                {contact.timeFrames.map((timeFrame, i) => (
                  <ProjectTimeFrame
                    key={`${idx}-timeFrame-${i}`}
                    showAddBtn={i === 0}
                    timeFrame={timeFrame}
                    onAdd={(timeFrame) => addNotificationContactTimeFrame({ timeFrame: timeFrame, NotificationContactId: contact.id })}
                    onUpdate={(existingTimeFrame: TimeFrame, newTimeFrame) => updateNotificationContactTimeFrame(
                      { ExistingTimeFrame: existingTimeFrame, UpdatedTimeFrame: newTimeFrame, NotificationContactId: contact.id })}
                    onDelete={() => deleteNotificationContactTimeFrame({ timeFrame: timeFrame, NotificationContactId: contact.id })}
                  />
                ))}
                <ProjectContact
                  key={idx}
                  contact={contact}
                  onToggleBatteryAlarmNotification={() => { console.log("toggleBattery"), toggleBatteryAlarmNotification({ NotificationContactId: contact.id }) }}
                  onToggleEmailNotification={() => toggleEmailNotification({ NotificationContactId: contact.id })}
                  onToggleSmsNotification={() => toggleSmsNotification({ NotificationContactId: contact.id })}
                  onDelete={(id) => deleteNotificationContact({ id }) }
                />
                <Divider key={`${idx}-divider`} />
              </div>
            ))}
        </>
      ) : (
        <Text mt={"sm"} size="sm">
          No contacts added to this project
        </Text>
      )}
    </>
  );
};
