import { useContext, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

import { PWAContext } from "../providers/PWAProvider";
import {
  getDeviceByReferenceId,
  setDeviceWorkzoneId,
} from "../../services/devices";
import { Device } from "../../models/Device";
import { BatteryStatus, EquipmentType } from "../../models/enums/DeviceEnums";
import { useQuery } from "@tanstack/react-query";

export const useDeviceConnection = (isHealthCheck?: boolean) => {
  const { instance } = useMsal();
  const { automaticDisconnect, equipmentType, setDevice, setMessage } =
    useContext(PWAContext);
  const navigate = useNavigate();
  const [referenceId, setReferenceId] = useState<string>("");

  const { isError, isLoading } = useQuery({
    queryKey: ["device", "referenceId", referenceId],
    enabled: referenceId != "",
    queryFn: () => {
      return getDeviceByReferenceId(instance, referenceId)
        .then((response) => {
          handleDevice(response);
          return response;
        })
        .catch(() => {
          setMessage({
            text: "The device you scanned could not be found",
            title: "Device not found",
            type: "info",
          });
          setDevice(null);
          setReferenceId("");
          return null;
        });
    },
  });

  const startConnect = (currentDevice: Device) => {
    if (currentDevice.availableForAssignment === false) {
      navigate("/pwa/projects/not-available-for-assignment");
      return;
    }
    if (currentDevice.batteryStatus === BatteryStatus.CRITICAL) {
      navigate("/pwa/projects/critical-battery");
      return;
    }

    if (currentDevice.batteryStatus === BatteryStatus.OUT_OF_BATTERY) {
      navigate("/pwa/projects/out-of-battery");
      return;
    }

    equipmentTypeNavigation();
  };

  const equipmentTypeNavigation = () => {
    equipmentType === EquipmentType.Sign
      ? navigate("/pwa/projects/pair")
      : navigate("/pwa/projects/summary");
  };

  const disconnect = (currentDevice: Device) => {
    if (!currentDevice || !currentDevice.id) {
      setMessage({
        text: "The scanned device could not be found",
        title: "Device not found",
        type: "error",
      });
      return;
    }
    setDeviceWorkzoneId(instance, null, currentDevice.id, null, null, null)
      .then(() => {
        setMessage({
          text: `Successfully unassigned device from ${currentDevice.currentEquipmentType === EquipmentType.Sign ? "sign" : "barrier"}`,
          title: "Unassign successful",
          type: "success",
        });
        navigate("/pwa/projects/scanner");
      })
      .catch(() => {
        setMessage({
          text: `Failed to unassign ${currentDevice.currentEquipmentType === EquipmentType.Barrier ? "barrier" : "sign"}`,
          title: "Failed to unassign",
          type: "error",
        });
      });
  };

  const startDisconnect = (currentDevice: Device) => {
    if (automaticDisconnect) {
      disconnect(currentDevice);
      return;
    }

    navigate("/pwa/projects/disconnect");
  };

  const handleDevice = (device: Device | undefined): void => {
    if (!device) {
      setMessage({
        text: "The device you scanned could not be found",
        title: "Device not found",
        type: "info",
      });
      setDevice(null);
      return;
    }

    setDevice(device);
    if (!isHealthCheck)
      device.workZoneId ? startDisconnect(device) : startConnect(device);
  };

  const handleConnection = (referenceId: string): void => {
    setReferenceId(referenceId);

    if (isError) {
      setMessage({
        text: "Something went wrong when fetching the device",
        title: "Failed to fetch device",
        type: "error",
      });
      setReferenceId("");
    }
  };

  return {
    disconnect,
    equipmentTypeNavigation,
    handleConnection,
    isLoading,
  };
};
