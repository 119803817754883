import { useQueryClient, useMutation } from "@tanstack/react-query";
import postData from "../../api/postData";
import { IPublicClientApplication } from "@azure/msal-browser";

interface Params {
  deviceId: string;
}

const path = `/device/replace-battery`;

const useDeviceReplaceBattery =  (instance: IPublicClientApplication) => {
  const queryClient = useQueryClient();
  const {
    mutate,
    mutateAsync,
    isPending,
    isError,
    isSuccess,
  } = useMutation({
    mutationFn: (params: Params) => { return postData<Params>(path, params, instance) },
    onError: (error) => {
      console.error(`Error registering battery replace event for device`, error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['devices'] })
    },
  });
  
  return {
    mutate,
    mutateAsync,
    isPending,
    isError,
    isSuccess,
  }
};

export default useDeviceReplaceBattery;