import { useEffect, useState } from "react";
import { IconRouterOff } from "@tabler/icons-react";
import { Space, Stack, Switch, Title, useMantineTheme } from "@mantine/core";
import { DeviceListItemLight } from "../../components/Device/DeviceListItem/DeviceListItemLight.tsx";
import { Device } from "../../models/Device.ts";
import { sortDevices } from "../../services/helpers.tsx";

type Props = {
  devices: Device[];
  isSnoozed?: boolean;
  countryCode: string;
};

const RECENTLY_ADDED_HOUR_THRESHOLD = 4;

export default function OngoingInstallationDeviceList({
  devices,
  isSnoozed,
  countryCode,
}: Props) {
  const [recentDevices, setRecentDevices] = useState<Device[]>([]);
  const [showRecent, setShowRecent] = useState(false);

  const theme = useMantineTheme();

  const filterRecent = () => {
    const now = new Date();
    const recently = new Date().setHours(
      now.getHours() - RECENTLY_ADDED_HOUR_THRESHOLD,
    );

    const added = devices.filter(
      (device) => new Date(device.assignedAtTimestamp) > new Date(recently),
    );

    setRecentDevices(added);
  };

  useEffect(() => {
    filterRecent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  const items = showRecent ? recentDevices : devices;

  const listDevices = () => {
    if (items.length === 0)
      return (
        <Stack align="center">
          <Space h="50px" />
          <IconRouterOff
            opacity={0.25}
            size="75px"
            stroke={1.25}
            style={{ transform: "rotate(-90deg)" }}
          />
          <Title opacity={0.5} fw={400} order={4} ta="center">
            {showRecent
              ? `No devices added the last ${RECENTLY_ADDED_HOUR_THRESHOLD} hours`
              : "No devices found"}
          </Title>
        </Stack>
      );

    return sortDevices(items).map((device) => (
      <DeviceListItemLight
        key={device.id}
        device={device}
        isSnoozed={isSnoozed}
        countryCode={countryCode}
        onClick={() => {}}
      />
    ));
  };

  return (
    <Stack gap="sm" style={{ overflow: "auto" }}>
      <Switch
        c="gray"
        checked={showRecent}
        onChange={(event) => setShowRecent(event.currentTarget.checked)}
        label={`Only show added last ${RECENTLY_ADDED_HOUR_THRESHOLD} hours`}
      />

      <Stack
        mt="sm"
        gap="sm"
        bd={
          items.length !== 0
            ? `solid ${theme.colors.dimmedText[0]} 1px`
            : "none"
        }
        style={{ overflow: "scroll", borderRadius: "4px" }}
      >
        {listDevices()}
      </Stack>
    </Stack>
  );
}
