import { Badge, Group, Text } from "@mantine/core";
import { IconZzz } from "@tabler/icons-react";

import styles from "./styles.module.css";

interface Props {
  size?: "md" | "lg";
}

export default function SnoozeBadge({ size = "lg" }: Props) {
  return (
    <Badge
      size={size}
      variant="light"
      className={styles.snoozed}
      leftSection={<IconZzz color="black" size={18} stroke={1.5} />}
    >
      <Group gap={6} ml={2}>
        <Text size="sm" c="black" fw={500}>
          Snoozed
        </Text>
      </Group>
    </Badge>
  );
}
