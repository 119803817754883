import { api } from "./api";
import { QueryParameters } from "./QueryParameters";
import { IPublicClientApplication } from "@azure/msal-browser";
import { WorkZone } from "../models/Project.ts";

const resource = "workZone";

class WorkZoneParams extends QueryParameters {
  id: string;

  constructor(id: string) {
    super();
    this.id = id;
  }
}

export interface Params {
  WorkZoneId: string;
}

export async function getWorkZones(
  instance: IPublicClientApplication,
  queryParams?: WorkZoneParams,
): Promise<WorkZone[]> {
  const data = await api.get(
    instance,
    resource,
    queryParams != null ? queryParams.toRecord() : {},
  );
  return (await data.json()) as WorkZone[];
}

export async function getProjectById(
  instance: IPublicClientApplication,
  projectId: string,
): Promise<WorkZone> {
  const data = await api.get(instance, `${resource}/${projectId}`, {});
  return (await data.json()) as WorkZone;
}

export async function getWorkZonesMap(
  instance: IPublicClientApplication,
  queryParams?: WorkZoneParams,
): Promise<{
  [workZoneId: string]: WorkZone;
}> {
  return await getWorkZones(instance, queryParams).then((data) =>
    data.reduce(
      (map, workZone) => {
        if (workZone.id != null) {
          map[workZone.id] = workZone;
        }
        return map;
      },
      {} as { [workZoneId: string]: WorkZone },
    ),
  );
}

export async function getProjectsByClosest(
  instance: IPublicClientApplication,
  coordinates: [x: number, y: number],
  amount?: number,
): Promise<WorkZone[]> {
  const data = await api.post(instance, `${resource}/closest`, {
    geometry: { type: "Point", coordinates },
    amount: amount || 5,
  });
  return (await data.json()) as WorkZone[];
}

