import { IPublicClientApplication } from "@azure/msal-browser";
import { api } from "../../../services/api.ts";
import { QueryParameters } from "../../../services/QueryParameters.ts";
import { SupervisionReportDto } from "../../../models/SupervisionReport.ts";
import { useQuery } from "@tanstack/react-query";
import QueryKeys from "../../queryKeys.ts";
import { SupervisionReportFilters } from "../../filters.ts";
import fetchDataSingleItem, { APIResponseSingleItem} from "../../api/fetchDataSingleItem.ts";

const resource = "supervisionReport";

export class SupervisionReportParams extends QueryParameters {
  workZoneId: string;
  startTime: string;
  endTime: string;
  interval: string;

  constructor(
    workZoneId: string,
    startTime: string,
    endTime: string,
    interval: string,
  ) {
    super();
    this.workZoneId = workZoneId;
    this.startTime = startTime;
    this.endTime = endTime;
    this.interval = interval;
  }
}

export async function getSupervisionReport(
  instance: IPublicClientApplication,
  queryParams?: SupervisionReportParams,
): Promise<SupervisionReportDto> {
  const data = await api.get(
    instance,
    resource,
    queryParams != null ? queryParams.toRecord() : {},
  );
  return (await data.json()) as SupervisionReportDto;
}

interface Params {
  instance: IPublicClientApplication;
  workZoneId: string;
  startTime: string;
  endTime: string;
  interval: string;
}

const path = `/supervisionReport`;

const useGetSupervisionReport = ({
  instance,
  workZoneId,
  startTime,
  endTime,
  interval,
}: Params) => {
  const pageNumber = 1;
  const pageSize = 1000;

  const { data, isLoading, isError } = useQuery<
    APIResponseSingleItem<SupervisionReportDto>
  >({
    queryKey: QueryKeys.SupervisionReports.list(pageNumber, pageSize, {
      workZoneId: workZoneId,
      startTime: startTime,
      endTime: endTime,
      interval: interval,
    } as SupervisionReportFilters),
    queryFn: () =>
      fetchDataSingleItem(
        path,
        {
          ...{
            workZoneId: workZoneId,
            startTime: startTime,
            endTime: endTime,
            interval: interval,
          },
        },
        instance,
      ),
    enabled: !!workZoneId,
  });
  
  return {
    supervisionReport: data?.item,
    isLoading,
    isError,
  };
};

export default useGetSupervisionReport;
