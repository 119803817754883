import { useContext, useEffect } from "react";
import { Button, Flex } from "@mantine/core";
import { PWAContext } from "../providers/PWAProvider";
import { useDeviceConnection } from "../hooks/useDeviceConnection";
import DeviceSummary from "../components/DeviceSummary.tsx";
import { getSignsByCountry } from "../../config/roadsigns.ts";

export interface Sign {
  name: string;
  value?: string;
}

export default function Disconnect() {
  const { disconnect } = useDeviceConnection();
  const { device, project } = useContext(PWAContext);
  const countrySigns = getSignsByCountry(project?.countryCode ?? "");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const deviceEquipmentValue = project
    ? `${project?.countryCode}_${
        Object.keys(countrySigns)[
          Object.values(countrySigns).indexOf(device?.attachmentRef)
        ]
      }`
    : undefined;

  return (
    <Flex h="100%" direction="column" justify="space-between" mb="lg">
      <DeviceSummary
        projectName={device?.workZoneName || "-"}
        referenceID={device?.referenceId || "-"}
        equipment={
          device?.currentEquipmentType
            ? {
                type: device.currentEquipmentType,
                name: device?.currentName || "-",
                value: deviceEquipmentValue,
              }
            : undefined
        }
        additionalInfo={device?.currentName}
      />

      <Button
        disabled={!device}
        size="xl"
        w="100%"
        onClick={() => device && disconnect(device)}
      >
        Remove from project
      </Button>
    </Flex>
  );
}
