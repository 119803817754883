import { BatteryStatus } from "../../models/enums/DeviceEnums";
import { BatteryIcon } from "../../pwa/components/BatteryIcon";
import getBadgeStateFromBatteryStatus from "../../pwa/utils/battery";
import Badge from "./Badge";

const BatteryStatusNames = {
  [BatteryStatus.OUT_OF_BATTERY]: "Out of battery",
  [BatteryStatus.CRITICAL]: "Critical",
  [BatteryStatus.LOW]: "Low",
  [BatteryStatus.OK]: "OK",
};

type Props = {
  batteryStatus: BatteryStatus | null;
  number?: number;
  size?: "sm" | "md";
};

export default function BatteryBadgeV2({
  batteryStatus,
  size = "md",
  number,
}: Props) {
  if (!batteryStatus) return null;

  return (
    <Badge
      state={getBadgeStateFromBatteryStatus(batteryStatus)}
      size={size}
      label={`${number} ${BatteryStatusNames[batteryStatus]}`}
      icon={<BatteryIcon batteryStatus={batteryStatus} size={16} />}
    />
  );
}
