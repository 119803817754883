import { ReactNode, createContext, useEffect, useState } from "react";
import { Sign } from "../routes/Pair";
import { ToastType } from "../components/Toast";
import {
  networkListener,
  removeNetworkListener,
} from "../utils/networkListener";
import { EquipmentType } from "../../models/enums/DeviceEnums";
import { Device } from "../../models/Device";
import { WorkZone } from "../../models/Project";

export type Message = {
  text: string;
  title: string;
  type: ToastType;
  indismissable?: boolean;
  static?: boolean;
};

export interface PWAContextValue {
  automaticDisconnect: boolean;
  setAutomaticDisconnect: (automatic: boolean) => void;
  equipmentType: EquipmentType;
  setEquipmentType: (type: EquipmentType) => void;
  message: Message | null;
  offline: boolean;
  setOffline: (offline: boolean) => void;
  setMessage: (message: Message | null) => void;
  device: Device | null;
  setDevice: (device: Device | null) => void;
  setProject: (project: WorkZone) => void;
  sign: Sign | null;
  setSign: (sign: Sign | null) => void;
  project: WorkZone | null;
}

const initialContext: PWAContextValue = {
  automaticDisconnect: false,
  setAutomaticDisconnect: () => {},
  equipmentType: EquipmentType.Sign,
  setEquipmentType: () => {},
  message: null,
  offline: false,
  setOffline: () => {},
  setMessage: () => {},
  device: null,
  setDevice: () => {},
  project: null,
  setProject: () => {},
  sign: null,
  setSign: () => {},
};

export const PWAContext = createContext(initialContext);

export default function PWAProvider({ children }: { children: ReactNode }) {
  const [project, setProject] = useState<WorkZone | null>(
    sessionStorage.getItem("project")
      ? JSON.parse(sessionStorage.getItem("project") || "")
      : null,
  );
  const [device, setDevice] = useState<Device | null>(null);
  const [sign, setSign] = useState<Sign | null>(null);
  const [automaticDisconnect, setAutomaticDisconnect] =
    useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [offline, setOffline] = useState<boolean>(false);
  const [equipmentType, setEquipmentType] = useState<EquipmentType>(
    EquipmentType.Sign,
  );

  useEffect(() => {
    networkListener(setMessage, setOffline);

    const storedProject = sessionStorage.getItem("project");
    if (storedProject) {
      setProject(JSON.parse(storedProject));
    }

    return () => {
      removeNetworkListener(setMessage);
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("project", JSON.stringify(project));
  }, [project]);

  useEffect(() => {
    if (!message || message.indismissable || message.static) return;
    setTimeout(() => {
      setMessage(null);
    }, 6000);
  }, [message]);

  return (
    <PWAContext.Provider
      value={{
        automaticDisconnect,
        setAutomaticDisconnect,
        equipmentType,
        setEquipmentType,
        message,
        offline,
        setOffline,
        setMessage,
        device,
        setDevice,
        sign,
        setSign,
        project,
        setProject,
      }}
    >
      {children}
    </PWAContext.Provider>
  );
}
