import {
  ActionIcon,
  Button,
  Group,
  Paper,
  Stack,
  TextInput,
  Title,
} from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import parsePhoneNumberFromString from "libphonenumber-js/min";

export const AddRecipiant = (props: {
  width: string;
  onSubmit: (props: { name: string; phone: string | undefined; email: string | undefined }) => void;
  onClose: () => void;
}) => {
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      name: "",
      phone: "",
      email: "",
    },
    validate: {
      name: isNotEmpty("You must provide a name"),
      phone: (value, values) => {
        // If email is filled and valid, no need to show phone as an error
        if (!value && !values.email) {
          return "Please provide either a valid phone number or email address";
        }
        if (value) {
          const phoneNumber = parsePhoneNumberFromString(value);
          return phoneNumber && phoneNumber.isValid() ? null : "Invalid phone number format";
        }
        return null; // No error if email is provided and valid
      },
      email: (value, values) => {
        // If phone is filled and valid, no need to show email as an error
        if (!value && !values.phone) {
          return "Please provide either a valid phone number or email address";
        }
        if (value) {
          const emailValidator = isEmail("Invalid email format");
          return emailValidator(value);
        }
        return null; // No error if phone is provided and valid
      },
    },
    validateInputOnBlur: true,
  });

  return (
    <>
      <Paper bg={"gray.0"} w={props.width} shadow="xs" p={"md"}>
        <form
          onSubmit={form.onSubmit((values) => {
            props.onSubmit({
              name: values.name,
              phone: values.phone ? values.phone : undefined,
              email: values.email ? values.email : undefined,
            });
          })}
        >
          <Group justify="space-between">
            <Title order={5}>Customer Contact Details </Title>
            <ActionIcon variant="subtle" onClick={() => props.onClose()}>
              <IconX></IconX>
            </ActionIcon>
          </Group>
          <Stack mt={"md"} gap={"sm"}>
            <TextInput
              placeholder="Name*"
              withAsterisk
              key={form.key("name")}
              {...form.getInputProps("name")}
            />
            <TextInput
              placeholder="Phone number"
              required={false}
              withAsterisk
              key={form.key("phone")}
              {...form.getInputProps("phone")}
            />
            <TextInput
              placeholder="Email address"
              required={false}
              withAsterisk
              key={form.key("email")}
              {...form.getInputProps("email")}
            />

            <Group justify="flex-end" mt="md">
              <Button type="submit">Add</Button>
            </Group>
          </Stack>
        </form>
      </Paper>
    </>
  );
};
