import {
    Stack,
    Text,
    Timeline,
    Title,
} from "@mantine/core";
import { useMsal } from "@azure/msal-react";
import { WorkZone } from "../../../models/Project.ts";
import useProjectCommandLogs from "../../../data/hooks/Project/useProjectCommandLogsHook.ts";
import { IconArchive, IconBell, IconBellOff, IconEdit, IconLink, IconSparkles } from "@tabler/icons-react";

const projectHistoryLogMap = {
    CreateWorkZoneCommand: {
        title: "Created",
        description: "created the project",
        icon: <IconSparkles size={12} />
    },
    SetWorkZoneInformationCommand: {
        title: "Edited",
        description: "edited the project",
        icon: <IconEdit size={12} />
    },
    SnoozeWorkZoneCommand: {
        title: "Snoozed",
        description: "snoozed the project",
        icon: <IconBellOff size={12} />
    },
    UnSnoozeWorkZoneCommand: {
        title: "Unsnoozed",
        description: "unsnoozed the project",
        icon: <IconBell size={12} />
    },
    ToggleExternalConnectionEnabledCommand: {
        title: "Toggled External Connection",
        description: "toggled exernal connection",
        icon: <IconLink size={12} />
    },
    CloseWorkZoneCommand: {
        title: "Closed",
        description: "closed the project",
        icon: <IconArchive size={12} />
    }
}

export const ProjectHistory = (props: { project: WorkZone }) => {
    const { instance } = useMsal();
    const { projectCommandLogs } = useProjectCommandLogs({ instance, projectId: props.project.id });
    return (
        <>
            <Title order={3}>Project History</Title>
            <Stack p={"md"}>
                <Timeline active={100} bulletSize={24} lineWidth={2}>
                    {projectCommandLogs.map(log => {
                        const eventType = log.eventType as keyof typeof projectHistoryLogMap;
                        const eventDetails = projectHistoryLogMap[eventType];

                        return (
                            <Timeline.Item key={log.id} bullet={eventDetails?.icon} title={eventDetails?.title ?? log?.eventType}>
                                <Text c="dimmed" size="sm">{log.createdBy} {eventDetails?.description}</Text>
                                <Text size="xs" mt={4}>{new Date(log.created).toLocaleString()}</Text>
                            </Timeline.Item>
                        )
                    }
                    )}
                </Timeline>
            </Stack >
        </>
    );
};
