import { useQuery } from "@tanstack/react-query";
import { IPublicClientApplication } from "@azure/msal-browser";
import { Country } from "../../../models/Country";
import { prepareHeaders } from "../../api/apiHelpers";

interface Params {
    instance: IPublicClientApplication;
}
const path = `/countries`
const baseUrl = import.meta.env.VITE_API_BASE_PATH;


const useCountries = ({ instance }: Params) => {
    const {
        data,
        isLoading,
        isError
    } = useQuery<Country[]>({
        queryKey: ["ALL_COUNTRIES"],
        queryFn: async () =>  {
            const response = await fetch(`${baseUrl}${path}`, {
                mode: 'cors',
                method: 'GET',
                headers: await prepareHeaders(instance)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        },
        refetchInterval: 600000,
    });

    return {
        countries: data ? data : [],
        isLoading,
        isError
    }
}

export default useCountries;