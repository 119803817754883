import {
  Button,
  Center,
  CloseButton,
  Input,
  InputWrapper,
  Table,
  Text,
} from "@mantine/core";
import { Device } from "../../../models/Device";
import { useState } from "react";
import { DeviceImage } from "../../../shared/DeviceImage";

type Props = {
  devices: Device[];
  showRemoveBtn?: boolean;
  onRemove: (id: string) => void;
};

export const ConnectedDevices = ({
  devices,
  showRemoveBtn = true,
  onRemove,
}: Props) => {
  const [searchString, setSearchString] = useState<string>("");

  const filteredDevices = devices.filter((device) =>
    [device.currentName, device.referenceId, device.imei].some((field) =>
      field?.toLowerCase().includes(searchString.toLowerCase()),
    ),
  );

  const rows = filteredDevices.map((d) => (
    <Table.Tr key={d.id}>
      <Table.Td>
        <DeviceImage device={d} />
      </Table.Td>
      <Table.Td>{d.referenceId}</Table.Td>
      <Table.Td>{d.currentName}</Table.Td>
      <Table.Td ta={"right"}>
        {showRemoveBtn && (
          <Button
            bg={"rgb(222, 45, 45, 0.8)"}
            size="xs"
            onClick={() => onRemove(d.id)}
          >
            Remove
          </Button>
        )}
      </Table.Td>
    </Table.Tr>
  ));
  return (
    <>
      <InputWrapper w={"100%"} ta={"left"} label="Connected Devices">
        <Input
          mb={"sm"}
          placeholder="Search device by Serial number, IMEI or Additional info"
          value={searchString}
          onChange={(event) => setSearchString(event.currentTarget.value)}
          rightSectionPointerEvents="all"
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => setSearchString("")}
            />
          }
        />
      </InputWrapper>
      <Table w={"100%"}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th></Table.Th>
            <Table.Th>REF-Id</Table.Th>
            <Table.Th>Additional Info</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        {rows.length > 0 && <Table.Tbody>{rows}</Table.Tbody>}
      </Table>
      {!rows.length && (
        <Center>
          <Text c="dimmed">No devices have been added to this project yet</Text>
        </Center>
      )}
    </>
  );
};
