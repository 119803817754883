export interface DeviceFilters {
  name?: string;
  projectName?: string;
  workZoneId?: string;
  searchTerm?: string;
}

export const defaultDeviceFilters: DeviceFilters = {
  name: "",
  projectName: "",
  workZoneId: "",
  searchTerm: "",
};

export interface DeviceLogFilters {
  deviceId?: string;
  searchTerm?: string;
}

export const defaultDeviceLogFilters: DeviceLogFilters = {
  deviceId: "",
  searchTerm: "",
};

export interface ProjectFilters {
  name?: string;
  searchTerm?: string;
  includeDemoProjects?: boolean;
  depot?: string[];
}

export const defaultProjectFilters: ProjectFilters = {
  name: "",
  searchTerm: "",
  includeDemoProjects: false,
  depot: []
};

export interface NotificationContactFilters {
  name?: string;
  workZoneId?: string;
  searchTerm?: string;
}

export const defaultNotificationContactFilters: NotificationContactFilters = {
  name: "",
  workZoneId: "",
  searchTerm: "",
};

export interface SupervisionReportFilters {
  name?: string;
  projectId?: string;
  startTime?: string;
  endTime?: string;
  interval?: string;
}

export const defaultSupervisionReportFilters: SupervisionReportFilters = {
  name: "",
  projectId: "",
  startTime: "",
  endTime: "",
  interval: "",
};

export interface BulkProvisionFilters {
  name?: string;
  searchTerm?: string;
}

export const defaultBulkProvisionFilters: BulkProvisionFilters = {
  name: "",
  searchTerm: "",
};
