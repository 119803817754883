import { createTheme } from "@mantine/core";

export default createTheme({
  primaryColor: "primaryGreen",
  black: "#1A1A1A",
  colors: {
    primaryGreen: [
      "#fff",
      "#fff",
      "#fff",
      "#fff",
      "#fff",
      "#398582",
      "#2E6B69",
      "#285958",
      "#fff",
      "#fff",
    ],
    primaryGreenLight: [
      "#E2F0F0",
      "#E2F0F0",
      "#E2F0F0",
      "#E2F0F0",
      "#E2F0F0",
      "#E2F0F0",
      "#E2F0F0",
      "#E2F0F0",
      "#E2F0F0",
      "#E2F0F0",
    ],
    deepGreen: [
      "#e8ebeb",
      "#d1d6d6",
      "#b9c2c2",
      "#a2adad",
      "#8b9999",
      "#748484",
      "#5d7070",
      "#455b5b",
      "#2e4747",
      "#173232",
    ],
    mainYellow: [
      "#fffce0",
      "#fff8ca",
      "#fff099",
      "#ffe862",
      "#ffe136",
      "#ffdc18",
      "#ffda00",
      "#e3c100",
      "#c9ab00",
      "#ae9300",
    ],
    sand: [
      "#fffaeb",
      "#fffbed",
      "#fffbef",
      "#fffcf1",
      "#fffcf3",
      "#fffdf5",
      "#fffdf7",
      "#fffef9",
      "#fffefb",
      "#fffffd",
    ],
    sandBg: [
      "#f8f6f1",
      "#edebe4",
      "#dbd4c5",
      "#c8bda1",
      "#b9a984",
      "#af9c70",
      "#ab9664",
      "#958254",
      "#857348",
      "#74633a",
    ],
    greyText: [
      "#575757",
      "#575757",
      "#575757",
      "#575757",
      "#575757",
      "#575757",
      "#575757",
      "#575757",
      "#575757",
      "#575757",
    ],
    dimmedText: [
      "#8E8E8E",
      "#8E8E8E",
      "#8E8E8E",
      "#8E8E8E",
      "#8E8E8E",
      "#8E8E8E",
      "#8E8E8E",
      "#8E8E8E",
      "#8E8E8E",
      "#8E8E8E",
    ],
    lightGrey: [
      "#F8F8F8",
      "#F8F8F8",
      "#F8F8F8",
      "#F8F8F8",
      "#F8F8F8",
      "#F8F8F8",
      "#F8F8F8",
      "#F8F8F8",
      "#F8F8F8",
      "#F8F8F8",
    ],
    successGreen: [
      "#008a19",
      "#008a19",
      "#008a19",
      "#008a19",
      "#008a19",
      "#008a19",
      "#008a19",
      "#008a19",
      "#008a19",
      "#008a19",
    ],
    warningYellow: [
      "#ffae00",
      "#ffae00",
      "#ffae00",
      "#ffae00",
      "#ffae00",
      "#ffae00",
      "#ffae00",
      "#ffae00",
      "#ffae00",
      "#ffae00",
    ],
    dangerRed: [
      "#e53400",
      "#e53400",
      "#e53400",
      "#e53400",
      "#e53400",
      "#e53400",
      "#e53400",
      "#e53400",
      "#e53400",
      "#e53400",
    ],
  },
  spacing: {
    none: "0px",
    xs: "4px",
    sm: "8px",
    md: "16px",
    lg: "24px",
    xl: "32px",
  },
  headings: {
    sizes: {
      h4: {
        fontSize: "24px",
        fontWeight: "700",
        lineHeight: "32px",
      },
      h5: {
        fontWeight: "200",
      },
    },
  },
});
