import { Point } from "geojson";

export interface Project {
  projectId: string | null;
  isDemoProject: boolean;
  name: string;
  customer: string;
  startDate: Date;
  endDate: Date | null;
  position: Point | null;
  isClosed: boolean | null;
  closedAt: Date | null;
  manualCenter: boolean;
  countryCode: string;
}

export interface AlarmEvent {
  id?: string;
  type: Alarm;
}

export enum StatusIcon {
  Alert = "Alert",
  BatteryWarning = "BatteryWarning",
  OK = "OK",
}

export enum Alarm {
  Fallen = "fallen",
  BatteryWarning = "low_battery",
}

export interface DeviceTag {
  name: string;
  position: { lat: number; lng: number };
  alarmEvents: Alarm[];
  signType: string;
}

export enum LocationMode {
  Automatic = "Automatic",
  Manual = "Manual",
}

export type Mapbounds = {
  northEastLongitude: number;
  northEastLatitude: number;
  southWestLongitude: number;
  southWestLatitude: number;
};

export type Depot = {
  depotCode: string;
  name: string;
  countryCode: string;
  id: string;
};
