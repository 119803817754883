import {
  Button,
  Checkbox,
  Group,
  Modal,
  ModalRoot,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { IconClock } from "@tabler/icons-react";
import { isNotEmpty, useForm } from "@mantine/form";
import { useMobileDevice } from "../../../hooks/useMobileDevice";
import { useEffect } from "react";
import { TimeFrame } from "../../../models/NotificationContact";
import { DaysOfWeekNames } from "../../../models/enums/NotificationContactsEnums";

export const AddTimeFrameModal = (props: {
  timeFrame: TimeFrame;
  opened: boolean;
  editModal: boolean;
  onSubmit: (timeFrame: TimeFrame) => void;
  open: () => void;
  close: () => void;
  onDelete: () => void;
}) => {
  const isMobile = useMobileDevice();
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      weekdays: [] as string[],
      start: "",
      end: "",
    },
    validate: {
      weekdays: (value) => (value.length > 0 ? null : "Days need to be set"),
      start: isNotEmpty("Start time can't be empty"),
      end: (value, values) => {
        if (!value) {
          return "End time can't be empty";
        }
        if (values.start && value <= values.start) {
          return "End time must be later than start time";
        }
        return null;
      },
    },
  });
  useEffect(() => {
    if (props.editModal) {
      form.setValues({
        weekdays: props.timeFrame.daysOfWeek.map((v) => v.toString()),
        start: props.timeFrame.start.slice(0, 5),
        end: props.timeFrame.end.slice(0, 5),
      });
    } else {
      form.setValues({
        weekdays: [],
        start: "",
        end: "",
      });
    }
  }, [props.editModal]);
  return (
    <ModalRoot
      opened={props.opened}
      onClose={props.close}
      fullScreen={isMobile}
      size={"auto"}
      centered
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fw={600}>Weekdays*</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={form.onSubmit((values) => {
              props.close();
              props.onSubmit({
                daysOfWeek: values.weekdays.map((day) => Number(day[0])),
                start: `${values.start}:00`,
                end: `${values.end}:00`,
              });
            })}
          >
            <Stack gap={"xs"}>
              <Group grow>
                {Object.values(DaysOfWeekNames).map((day, i) => (
                  <Stack key={`day-${i}`} align="center" gap={"xs"}>
                    <Checkbox
                      key={`checkbox-${i}`}
                      value={i.toString()}
                      checked={form.getValues().weekdays.includes(i.toString())}
                      onChange={(event) => {
                        const value = event.currentTarget.value;
                        const updatedWeekdays = form.getValues().weekdays.includes(value)
                          ? form.getValues().weekdays.filter((day) => day !== value)
                          : [...form.getValues().weekdays, value];
                        form.setValues({ weekdays: updatedWeekdays });
                      }}
                    />
                    <Text size="xs">{day}</Text>
                  </Stack>
                ))}
              </Group>
              <Title mt={"md"} fw={600} order={4}>
                Time
              </Title>
              <Group align="top" wrap={isMobile ? "wrap" : "nowrap"}>
                <TimeInput
                  description="From"
                  w={"100%"}
                  leftSection={<IconClock size={"16px"} />}
                  key={form.key("start")}
                  {...form.getInputProps("start")}
                />

                <TimeInput
                  description="To"
                  w={"100%"}
                  leftSection={<IconClock size={"16px"} />}
                  key={form.key("end")}
                  {...form.getInputProps("end")}
                />
              </Group>
              <Space h={"md"}></Space>
              <Button type="submit">
                {props.editModal ? "Update" : "Add"}
              </Button>
              {props.editModal ? (
                <Button
                  onClick={() => {
                    props.close();
                    props.onDelete();
                  }}
                  variant="subtle"
                  c={"red"}
                >
                  Remove Timeframe
                </Button>
              ) : null}
            </Stack>
          </form>
        </Modal.Body>
      </Modal.Content>
    </ModalRoot>
  );
};
