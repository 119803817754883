import React from 'react';
import { Alert, Box, Group, Stack, Text } from '@mantine/core';
import { Dropzone, FileWithPath } from '@mantine/dropzone';

interface FileDropzoneProps {
    onDrop: (files: FileWithPath[]) => void;
    fileError: string;
    provisionSuccess: string;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({ onDrop, fileError, provisionSuccess }) => {
    return (
        <Stack gap={"lg"}>
            {fileError && (
                <Alert title="Error" color="red">
                    {fileError}
                </Alert>
            )}
            {provisionSuccess && (
                <Alert title="Success" color="green">
                    {provisionSuccess}
                </Alert>
            )}
            <Box p="lg" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Dropzone
                    onDrop={onDrop}
                    style={{ width: 500, height: 100, border: '2px dashed #ccc', borderRadius: '8px' }}
                >
                    <Group justify="center" style={{ height: '100%' }}>
                        <Text size="lg" inline>
                            Drag CSV file here or click to select
                        </Text>
                    </Group>
                </Dropzone>
            </Box>
        </Stack>
    );
};

export default FileDropzone;