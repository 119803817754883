import { useMobileDevice } from "../../hooks/useMobileDevice.tsx";
import { Modal } from "@mantine/core";
import { useMsal } from "@azure/msal-react";
import { Device } from "../../models/Device.ts";
import useAssignDeviceToProject from "../../data/hooks/Device/useAssignDeviceToProjectHook.ts";
import {
  AddDeviceForm,
  AddDeviceInput,
} from "../Project/ManageDevices/AddDeviceInput.tsx";

interface Props {
  device: Device;
  showModal: boolean;
  close: () => void;
  onSubmit: () => void;
}

export const AssignToProjectModal = ({ device, showModal, close }: Props) => {
  const { instance } = useMsal();
  const isMobile = useMobileDevice();

  const { mutateAsync: mutate } = useAssignDeviceToProject(instance);

  const handleAssignToProject = (data: AddDeviceForm | null) => {
    if (!data) return;
    mutate({
      workZoneId: data.projectId,
      deviceId: device.id,
      equipmentType: data.equipmentType,
      currentName: data.currentName,
      attachmentRef: data.attachmentRef,
    });
    close();
  };

  return (
    <Modal.Root
      opened={showModal}
      onClose={close}
      fullScreen={isMobile}
      centered
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fw={600}>Assign to project</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <AddDeviceInput
            deviceId={device.id}
            onAbort={close}
            onSubmit={(data) => (handleAssignToProject(data), close())}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
