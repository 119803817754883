import { useState } from "react";
import { Device } from "../../../models/Device";
import { WorkZone } from "../../../models/Project";
import DeviceMarkerComponent from "./DeviceMaker/DeviceMarker";
import { DotMarker } from "./DotMarker";
import { ProjectMarkerComponent } from "./ProjectMarker";
import { ProjectAreaComponent } from "../ProjectArea";
import { Notification } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { MapConstants } from "../MapConstants";

type Props = {
  projects: WorkZone[];
  projectDevices: { [projectsId: string]: Device[] };
  map: google.maps.Map | null;
  devices: Device[];
  onClick: (device: Device | null, project: WorkZone | null) => void;
  selectedDevice?: string | null;
};

export const MarkerWrapper = ({
  projects,
  projectDevices,
  onClick,
  map,
  devices,
  selectedDevice,
}: Props) => {
  const [zoom, setZoom] = useState<number | undefined>(6);
  const projectMarkers = projects.map((p) => (
    <ProjectMarkerComponent
      key={p.id}
      onClick={() => onClick(null, p)}
      workZone={p}
      devices={projectDevices[p.id]}
      showDotMarker={false}
      map={map}
    />
  ));
  const dotMarkers = projects.map((p) => (
    <DotMarker
      key={p.id}
      onClick={() => onClick(null, p)}
      workZone={p}
      map={map}
    />
  ));
  const deviceMarkers = (
    devices.length > MapConstants.MAX_DEVICE_RENDER ? [] : devices
  ).map((d) => (
    <DeviceMarkerComponent
      key={d.id}
      onClick={() => onClick(d, null)}
      device={d}
      map={map}
      isSelected={selectedDevice === d.id}
    />
  ));
  const projectAreas = projects.map((proj) => (
    <ProjectAreaComponent area={proj.area} map={map} key={proj.id} />
  ));
  const tooManyDevicesError = (
    <Notification
      style={{
        position: "absolute",
        bottom: "16px",
        right: "16px",
        zIndex: 2,
      }}
      w={"fit-content"}
      icon={<IconX />}
      p="sm"
      color="red"
      title="Too many devices to render"
    >
      Unable to render, try to zoom in
    </Notification>
  );
  map?.addListener("zoom_changed", () => {
    setZoom(map.getZoom());
  });
  return (
    <>
      {zoom && zoom > MapConstants.DEVICE_ZOOM && deviceMarkers}
      {zoom && zoom > MapConstants.DEVICE_ZOOM && projectAreas}
      {zoom &&
        zoom <= MapConstants.DEVICE_ZOOM &&
        zoom > MapConstants.PROJECT_ZOOM &&
        projectMarkers}
      {zoom && zoom <= MapConstants.PROJECT_ZOOM && dotMarkers}
      {/* This should be in a notification service , later todo */}
      {zoom &&
        zoom > MapConstants.DEVICE_ZOOM &&
        devices.length > MapConstants.MAX_DEVICE_RENDER &&
        tooManyDevicesError}
    </>
  );
};
