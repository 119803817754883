import { useRef, useState } from "react";
import { Combobox, Loader, TextInput, useCombobox } from "@mantine/core";
import { useMediaQuery } from "react-responsive";
import { useDebouncedCallback } from "@mantine/hooks";
import { Employee } from "../../models/Employee";

export const SearchEmployee = (props: {
  width: string;
  onChange: (props: {
    externalUserId: string;
    name: string;
    phone: string;
    email: string;
  }) => void;
  onClose: () => void;
  fetchEmployees: (query: string) => Promise<Employee[]>;
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const mobile = useMediaQuery({ query: "(max-width: 992px)" });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Employee[] | null>(null);
  const [value, setValue] = useState("");
  const abortController = useRef<AbortController>();

  const fetchOptions = (query: string) => {
    if (query.length < 2) {
      setLoading(false);
      setData(null);
      return;
    }

    abortController.current?.abort();
    abortController.current = new AbortController();
    setLoading(true);

    props
      .fetchEmployees(query)
      .then((result) => {
        setData(result);
        setLoading(false);
        abortController.current = undefined;
      })
      .catch(() => { });
  };

  const handleSearch = useDebouncedCallback(async (query: string) => {
    fetchOptions(query);
  }, 500);

  const handleSubmit = (optionValue: string) => {
    const selectedIndex = parseInt(optionValue, 10);
    const selectedEmployee = data?.[selectedIndex];

    if (selectedEmployee) {
      props.onChange({
        externalUserId: selectedEmployee.externalUserId,
        name: selectedEmployee.name,
        phone: selectedEmployee.phone,
        email: selectedEmployee.email,
      });
    }

    setValue("");
    setData([]);
    combobox.closeDropdown();
  };

  const options = (data || []).map((item, index) => (
    <Combobox.Option value={index.toString()} key={item.externalUserId}>
      {item.name}
    </Combobox.Option>
  ));

  return (
    <Combobox
      onOptionSubmit={handleSubmit}
      withinPortal={false}
      store={combobox}
    >
      <Combobox.Target>
        <TextInput
          placeholder="Search for a colleague"
          value={value}
          onChange={(event) => {
            setValue(event.currentTarget.value);
            handleSearch(event.currentTarget.value);
            combobox.resetSelectedOption();
            combobox.openDropdown();
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => {
            combobox.openDropdown();
            if (data === null) {
              handleSearch(value);
            }
          }}
          onBlur={() => combobox.closeDropdown()}
          rightSection={loading && <Loader size={18} />}
          style={{ width: mobile ? "100%" : props.width }}
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={data === null}>
        <Combobox.Options>
          {options}
          {data?.length == 0 && (
            <Combobox.Empty>No results found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
