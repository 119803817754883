import { useMsal } from "@azure/msal-react";
import { Navigate, useLocation } from "react-router-dom";

export default function Redirect() {
  const location = useLocation();
  const { instance } = useMsal();
  const from = location.state?.from ?? location.pathname; // Fallback to current path if 'from' is undefined
  if (from === "/login") {
    const preLogingRoute = localStorage.getItem("preLoginRoute") ?? "/projects";
    return <Navigate to={preLogingRoute} />;
  }

  //Runs if user is logged in
  if (instance.getActiveAccount()) {
    const preLogingRoute = localStorage.getItem("preLoginRoute") ?? "/projects";
    localStorage.removeItem("preLoginRoute"); // Clear saved route after use
    return <Navigate to={preLogingRoute} />;
  }

  //Runs if user is not logged in
  localStorage.setItem("preLoginRoute", from);
  return <Navigate to={"/login"} />;
}
