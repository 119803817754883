import { IPublicClientApplication } from "@azure/msal-browser";
import { useQuery } from "@tanstack/react-query";
import QueryKeys from "../../queryKeys.ts";

import fetchDataSingleItem, { APIResponseSingleItem} from "../../api/fetchDataSingleItem.ts";
import {UserInformationDto} from "../../../models/User.ts";

const path = `/user`;

interface Params {
  instance: IPublicClientApplication;
}

const useGetMyUserInformation = ({instance}: Params) => {
  const { data, isLoading, isError } = useQuery<
    APIResponseSingleItem<UserInformationDto>
  >({
    queryKey: QueryKeys.UserInformation.all,
    queryFn: () =>
      fetchDataSingleItem(
        path,
        {},
        instance,
      ),
    enabled: true,
  });
  
  return {
    userInformation: data,
    isLoading,
    isError,
  };
};

export default useGetMyUserInformation;
