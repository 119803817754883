import { ActionIcon, Avatar, Checkbox, Group, Text } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { useMobileDevice } from "../../../hooks/useMobileDevice";
import { NotificationContact } from "../../../models/NotificationContact";

export const ProjectContact = (props: {
  contact: NotificationContact;
  onToggleBatteryAlarmNotification: () => void;
  onToggleEmailNotification: () => void;
  onToggleSmsNotification: () => void;
  onDelete: (contactId: string) => void;
}) => {
  const isMobile = useMobileDevice();
  const nameParts = props.contact.name.split(' ');
  const initials = (!props.contact.external && nameParts[0]?.[0] && nameParts[1]?.[0]) ? nameParts[0][0] + nameParts[1][0] : null;
  return (
    <>
      <Group p={"sm"}>
        <Group wrap="nowrap" pr={"sm"} w={isMobile ? "100%" : "30%"}>
          <Avatar color={"blue"} size={"36px"}>{initials}</Avatar>
          <div style={{ width: "80%" }}>
            <Text size="sm">{props.contact.name}</Text>
            <Text size="xs" c={"gray.6"}>
              {props.contact.phone}
            </Text>
            <Text truncate={"end"} size="xs" c={"gray.6"}>
              {props.contact.email}
            </Text>
          </div>
        </Group>
        <Group style={{ width: "150px" }}>
          <Checkbox
            checked={props.contact.smsEnabled}
            disabled={null == props.contact.phone}
            onChange={props.onToggleSmsNotification}
            label="SMS"
          ></Checkbox>
          <Checkbox
            checked={props.contact.emailEnabled}
            disabled={null == props.contact.email}
            onChange={props.onToggleEmailNotification}
            label="Email"
          ></Checkbox>
        </Group>
        <ActionIcon variant="subtle">
          <IconTrash
            onClick={() => props.onDelete(props.contact.id)}
            color="#E53400"
            size={"30px"}
            data-testid="trash-btn"
          ></IconTrash>
        </ActionIcon>
      </Group >
      {props.contact.external ? (
        <Group style={{ width: "30%" }} pb="md" pl="sm">
          <div style={{ width: "36px" }}></div>
          <Checkbox
            size="xs"
            checked={props.contact.batteryAlarmNotificationEnabled}
            onChange={props.onToggleBatteryAlarmNotification}
            label="Send battery warnings"
          ></Checkbox>
        </Group>
      ) : null}
    </>
  );
};
