import { Message } from "../providers/PWAProvider";

const getCurrentPosition = (
  setCoordinates: (coordinates: {
    latitude: number | null;
    longitude: number | null;
  }) => void,
  onError: (message: Message) => void,
  setLoading: (loading: boolean) => void,
) => {
  navigator.geolocation.getCurrentPosition((position) =>
    setCoordinates({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    }),
  ),
    () => {
      onError({
        title: "Failed to get position",
        text: "Something went wrong when trying to get your current position",
        type: "error",
      });
      setLoading(false);
    };
};

const handlePermissions = (
  state: PermissionState,
  setCoordinates: (coordinates: {
    latitude: number | null;
    longitude: number | null;
  }) => void,
  onError: (message: Message) => void,
  setLoading: (loading: boolean) => void,
) => {
  if (state === "granted") {
    getCurrentPosition(setCoordinates, onError, setLoading);
  } else if (state === "prompt") {
    getCurrentPosition(setCoordinates, onError, setLoading);
  } else if (state === "denied") {
    onError({
      title: "Permission denied",
      text: "You have denied permissions to use your current location. Go to Settings on your device to change this. For more help, see the Help section in the top right corner",
      type: "error",
      static: true,
    });
    setCoordinates({
      latitude: null,
      longitude: null,
    }),
      setLoading(false);
  }
};

export function getLocation(
  setCoordinates: (coordinates: {
    latitude: number | null;
    longitude: number | null;
  }) => void,
  onError: (message: Message) => void,
  setLoading: (loading: boolean) => void,
) {
  if (!navigator || !navigator.geolocation) {
    onError({
      title: "Geolocation not supported",
      text: "Getting your current location is not supported by your device",
      type: "error",
    });
    setLoading(false);
    return;
  }

  navigator.permissions.query({ name: "geolocation" }).then((result) => {
    handlePermissions(result.state, setCoordinates, onError, setLoading);

    result.addEventListener("change", () => {
      handlePermissions(result.state, setCoordinates, onError, setLoading);
    });
  });
}
